import { Button } from '@vp/swan'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
export const SaveAndContinueBtn: FC<{
  newAddress?: boolean
  hasNext: boolean
  onClick: () => void
  disabled: boolean
}> = ({ newAddress, hasNext, onClick, disabled }) => (
  <Button type="submit" skin="primary" width="full-width" style={{ width: '-webkit-fill-available' }} disabled={disabled} onClick={onClick}>
    {hasNext ? (
      <FormattedMessage
        defaultMessage="Save and Continue"
        description="Button label to save an updated address and continue further to edit other addresses in the list"
      />
    ) : newAddress ? (
      <FormattedMessage defaultMessage="Add" description="Button label to add a new address" />
    ) : (
      <FormattedMessage defaultMessage="Save" description="Button label to save an updated address" />
    )}
  </Button>
)
