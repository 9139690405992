import { Button, FlexBox, Icon, Responsive, StyleSpace, Typography } from '@vp/swan'
import { FC, PropsWithChildren } from 'react'
import { useAddAddressActions } from './add-address-action.context'
import { useProductInfoQtySelection } from 'lib/product-info'
import { useAddressList } from 'lib/address-list'
import { AdditionalTrackingDataType, PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { useEventTracking } from 'lib/telemetry'

export const AddButton: FC<
  PropsWithChildren<{
    disabledCheck: boolean
    forSavedListFlow?: boolean
    showIcon?: boolean
    skin?: 'primary' | 'secondary' | undefined
    marginLeft?: 'auto' | undefined
    justifyContent?: 'center' | undefined
    px?: Responsive<StyleSpace>
  }>
> = ({ disabledCheck, forSavedListFlow, skin, showIcon, marginLeft, justifyContent, px, children }) => {
  const { onAdd } = useAddAddressActions()

  const { selectedQty: selectedEnvelopes } = useProductInfoQtySelection()
  const { data: addresses } = useAddressList()

  const isAddressesEqualToEnvelopes = addresses?.addressIds?.length
    ? addresses?.addressIds?.length >= selectedEnvelopes
    : addresses?.addressIds?.length === selectedEnvelopes

  const shouldShowAddMoreEnvelopesModal = forSavedListFlow && isAddressesEqualToEnvelopes

  const fireTracking = useEventTracking()
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)

  const flyOutTrackingData: AdditionalTrackingDataType = {
    category: TrackingCategoryType.FLY_OUT,
    label: TrackingLabelType.FLY_OUT_SELECTION,
    pageName: trackingProductPageName,
  }
  const handleClick = () => {
    onAdd(forSavedListFlow, shouldShowAddMoreEnvelopesModal)
    if (!shouldShowAddMoreEnvelopesModal) {
      fireTracking(TrackingEventType.FLY_OUT_VIEWED, flyOutTrackingData)
    }
  }

  return (
    <FlexBox alignItems="center" marginLeft={marginLeft} justifyContent={justifyContent}>
      <Button iconPosition="left" skin={skin} onClick={handleClick} hidden={disabledCheck} px={px} fontWeight="bold">
        {showIcon && <Icon iconType="add" size="20p" skin={'standard'} />}
        <Typography fontSize={'small'}>{children}</Typography>
      </Button>
    </FlexBox>
  )
}
