import { Formik, FormikValues, FormikProps } from 'formik'
import { useLocalization } from 'hooks/use-localization'
import { getTopDomain } from 'lib/site-links/site-link.utils'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { Dispatch, FC, RefObject, SetStateAction, useCallback, useState } from 'react'
import { VpAddress, VpMailingList, VpRecipientFormFields } from 'types/vp-address-form.types'
import { Notifier } from 'utilities/Notifier'
import { AddressFormCustomInputs } from './address-form-custom-inputs.component'
import { FORM_TYPES } from './address-form.constants'
import { getModifiedLastName } from 'utilities/address.utils'

type Props = {
  onValueChanged?: (isValid: boolean) => void
  address: Partial<IAddressDetails>
  onSubmit: (newAddress: VpAddress) => void
  addressRef: RefObject<FormikProps<VpAddress>>
  submitButtonDisabled: boolean
  setSubmitButtonDisabled: Dispatch<SetStateAction<boolean>>
  notifier: Notifier
  formType?: FORM_TYPES
}

export const AddressFormWrapper: FC<Props> = ({ onValueChanged, address, addressRef, onSubmit, notifier, formType = FORM_TYPES.ADDRESS }) => {
  const { locale } = useLocalization()
  const countryFromTopDomain = `${getTopDomain(locale)}`?.toUpperCase()
  const [isRecipientFormValid, setIsRecipientFormValid] = useState(true)

  const defaultAddress = {
    salutation: '',
    firstName: '',
    middleName: '',
    lastName: getModifiedLastName(address) || '',
    suffix: '',
    title: '',
    streetName: address?.streetName || '',
    additionalStreetInfo: address?.additionalStreetInfo || '',
    postalCode: address?.postalCode || '',
    city: address?.city || '',
    state: address?.state || '',
    country: countryFromTopDomain,
    phone: '',
    company: address?.company || '',
    email: '',
  }

  const [recipientAddress, setRecipientAddress] = useState<VpRecipientFormFields>(defaultAddress)

  const onValueChangedHandler = useCallback(
    (newValues: FormikValues, isValid: boolean) => {
      setRecipientAddress(newValues as VpRecipientFormFields)
      setIsRecipientFormValid(isValid)
      if (onValueChanged) onValueChanged(isValid)
    },
    [setRecipientAddress],
  )
  return (
    <Formik
      initialValues={defaultAddress}
      onSubmit={(newValue: VpAddress & VpMailingList) => {
        if (isRecipientFormValid) {
          onSubmit({ ...newValue, ...recipientAddress })
        }
      }}
      innerRef={addressRef}
    >
      <AddressFormCustomInputs onValueChanged={onValueChangedHandler} notifier={notifier} formType={formType} />
    </Formik>
  )
}
