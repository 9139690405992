import { ModalDialogBody, ModalDialogFooter } from '@vp/swan'
import { AddressFormWrapper } from 'components/address-form/address-form-wrapper.component'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { ErrorBoundary } from 'lib/errors'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VpAddress, VpMailingList } from 'types/vp-address-form.types'
import { Notifier } from 'utilities/Notifier'
import { SuggestedAddress } from '../suggested-address/suggested-address.component'
import { SaveAndContinueBtn } from './save-continue-btn.component'
import { FormikProps } from 'formik'
import { useSuggestedAddressTitle } from 'lib/intl/msg-address-dialog.hooks'
import { FORM_TYPES } from 'components/address-form/address-form.constants'
import { DoneOrNextBtn } from '../modal-add/done-next-btn.component'
import { useAddressList } from 'lib/address-list'
import { useProductInfoQtySelection } from 'lib/product-info'
import { AdditionalTrackingDataType, PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { useEventTracking } from 'lib/telemetry'

export const SingleRowEdit: FC<{
  formAdd?: boolean
  savedListFlow?: boolean
  newAddress?: boolean
  address: IAddressDetails
  suggestion?: IAddressDetails
  onUpdate: (address: IAddressDetails & VpMailingList) => void
  onExit: () => void
  editInProgress: boolean
  showError: boolean
  hasToReset: boolean
  formType: FORM_TYPES
}> = ({ formAdd, newAddress, address, suggestion, onUpdate, onExit, editInProgress, showError, formType, savedListFlow, hasToReset }) => {
  const { data: addresses } = useAddressList()
  const { selectedQty: selectedEnvelopes } = useProductInfoQtySelection()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false)
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [submitButtonDisabledAdd, setSubmitButtonDisabledAdd] = useState<boolean>(false)
  const submitButtonCount = useRef(0) // this counter normalise the condition for disabling the add button since initially isValid is set to true by default
  const onValueChangedHandleEdit = useCallback((isValid: boolean) => {
    setSubmitButtonDisabledAdd(isValid)
    setFormSubmitted(false)
    submitButtonCount.current = submitButtonCount.current + 1
  }, [])

  const { logWarn } = useLogger()

  const addressFormRef = useRef<FormikProps<VpAddress & VpMailingList>>(null)
  useEffect(() => {
    if (addressFormRef.current?.isValid) {
      setFormSubmitted(false)
    }
  }, [addressFormRef.current?.isValid])
  const disableCheckAdd = editInProgress || !submitButtonDisabledAdd
  const disableCheckEdit = editInProgress || submitButtonDisabled
  const onFormSubmit = (newAddress: VpAddress & VpMailingList) => {
    const transformedAddress = {
      ...address,
      ...newAddress,
      metadata: undefined,
    }
    onUpdate(transformedAddress)
  }
  const workId = useWorkIdQueryParam()
  useEffect(() => {
    if (showError) {
      logWarn('Error occurred while updating address', { contextData: { workId, id: address.rowIndex } })
    }
  }, [showError, workId, address.rowIndex, submitButtonDisabled, logWarn])
  const suggestedAddressTitle = useSuggestedAddressTitle()
  const notifier = useMemo(() => new Notifier(), [])
  const clickDelay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)
  const fireTracking = useEventTracking()
  const hasNext = (addresses?.addressIds?.length || 0) < selectedEnvelopes - 1
  useEffect(() => {
    if (hasToReset) {
      addressFormRef?.current?.resetForm()
    }
  }, [hasToReset])

  return (
    <ErrorBoundary>
      {/* <Helmet>
        <script type="text/javascript" src={autocompleteScript} />
      </Helmet> */}
      <ModalDialogBody id="dialog-body">
        {suggestion ? (
          <ErrorBoundary>
            <SuggestedAddress address={suggestion} titleForAddress={suggestedAddressTitle} onAddressSelection={() => onUpdate({ ...suggestion })} />
          </ErrorBoundary>
        ) : null}
        <ErrorBoundary>
          <AddressFormWrapper
            onValueChanged={onValueChangedHandleEdit}
            address={address}
            formType={formType}
            onSubmit={onFormSubmit}
            addressRef={addressFormRef}
            submitButtonDisabled={submitButtonDisabled}
            setSubmitButtonDisabled={setSubmitButtonDisabled}
            notifier={notifier}
          />
        </ErrorBoundary>
      </ModalDialogBody>

      <ModalDialogFooter id="dialog-footer" pinned>
        {savedListFlow ? (
          <DoneOrNextBtn
            hasNext={hasNext}
            onExit={onExit}
            onClick={async () => {
              const flyOutTrackingData: AdditionalTrackingDataType = {
                category: TrackingCategoryType.FLY_OUT,
                label: TrackingLabelType.FLY_OUT_SELECTION,
                pageName: trackingProductPageName,
                eventDetail: hasNext ? 'Save and Continue' : 'Save',
              }
              fireTracking(TrackingEventType.FLY_OUT_CLICKED, flyOutTrackingData)
              notifier.notify()
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await addressFormRef?.current?.submitForm()
              setFormSubmitted(true)
              if ((addresses?.addressIds?.length || 0) >= selectedEnvelopes - 1) {
                await clickDelay(3500)
                document.getElementById('done-button')?.click()
              }
            }}
            disabled={formAdd ? formSubmitted || disableCheckAdd || submitButtonCount.current <= 2 : disableCheckEdit}
          />
        ) : (
          <SaveAndContinueBtn
            newAddress={newAddress}
            hasNext={false}
            onClick={async () => {
              notifier.notify()
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await addressFormRef?.current?.submitForm()
            }}
            disabled={formAdd ? disableCheckAdd || submitButtonCount.current <= 2 : disableCheckEdit}
          />
        )}
      </ModalDialogFooter>
    </ErrorBoundary>
  )
}
