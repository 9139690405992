import { useIntl } from 'react-intl'

export function useMsgSelectedAddresses(count: number) {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: '{count} selected', description: 'Shows total numbers of addresses selected' }, { count })
}

export function useMsgSelectAllValid() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Select all okay',
    description: 'Checkbox title to select all options e.g. Select all okay',
  })
}

export function useMsgSelectAllInvalids() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Select all errors',
    description: 'Checkbox title to select all options e.g. Select all errors',
  })
}

export function useMsgSelectAllDuplicates() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Select all duplicates',
    description: 'Checkbox title to select all options e.g. Select all duplicates',
  })
}

export function useMsgSelectedAddressesSavedFlow(count: number) {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: '{count} recipients selected', description: 'Shows total numbers of addresses selected' }, { count })
}
