import { useRequestIdQueryParam } from 'contexts/query-param.context'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { useAddressListReset } from 'lib/address-list'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { useEventTracking } from 'lib/telemetry'
import { PageName, TrackingCategoryType } from 'lib/telemetry/tracking.types'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useReviewStrategyConfig } from 'modules/review/contexts/review-config.context'
import { SelectionStrategy } from 'modules/review/types/address-list.types'
import { useCallback, useState } from 'react'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'

export function useStrategySelectorChangeHandler(
  onChange: (nextStrategy: string, resetAddresses: UploadedAddressesResponse | undefined) => void,
  onDataLoad: () => void,
) {
  const requestId = useRequestIdQueryParam()
  const [strategy, setStrategy] = useReviewStrategyConfig()
  const [inProgress, setInProgress] = useState(false)
  const {
    isEnabled: isResetEnabled,
    mutationResult: { mutate: resetList, isLoading: isResetInProgress },
  } = useAddressListReset()
  const unknownErrorToast = useToastSomethingWentWrong()
  const fireTracking = useEventTracking()
  const disabled = !isResetEnabled || !requestId || isResetInProgress
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)
  const { logError } = useLogger()

  const changeStrategy = useCallback(
    (nextStrategy: string | null | undefined) => {
      const buttonLabel = nextStrategy === SelectionStrategy.MANUAL ? 'I’ll fix the issues myself' : 'Fix issues for me'
      const trackingData = {
        category: TrackingCategoryType.CONFIGURE_MAILING,
        label: buttonLabel,
        pageName: trackingProductPageName,
      }
      fireTracking(`${buttonLabel} Clicked`, trackingData)
      try {
        setInProgress(true)
        if (!disabled && nextStrategy && nextStrategy !== strategy) {
          resetList(
            { requestId },
            {
              onSuccess: data => {
                setStrategy(nextStrategy)
                onChange(nextStrategy, data)
                onDataLoad()
              },
            },
          )
        }
      } catch {
        unknownErrorToast()
        logError('Error occurred while updating strategy for fixing address issues', { contextData: { requestId, strategy } })
      } finally {
        setInProgress(false)
      }
    },
    [fireTracking, disabled, strategy, resetList, requestId, setStrategy, onChange, unknownErrorToast, onDataLoad, trackingProductPageName],
  )

  return { inProgress: inProgress || isResetInProgress, disabled, changeStrategy, strategy }
}
