import { useIntl } from 'react-intl'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

export function useMsgDuplicateAddress(count: number) {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (count && !loadingManualAddressingFlag) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage(
          { defaultMessage: '{count} Duplicate recipients', description: 'Shows total number of duplicate recipients, e.g. 3 duplicates' },
          { count },
        )
      : intl.formatMessage(
          { defaultMessage: '{count} Duplicate contacts', description: 'Shows total number of duplicate addresses, e.g. 3 duplicates' },
          { count },
        )
  } else {
    return ''
  }
}

export function useMsgInvalidAddress(count: number) {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (count && !loadingManualAddressingFlag) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage({ defaultMessage: '{count} Invalid recipients', description: 'Shows total number of invalid recipients, e.g. 3 errors' }, { count })
      : intl.formatMessage({ defaultMessage: '{count} Invalid contacts', description: 'Shows total number of invalid addresses, e.g. 3 errors' }, { count })
  } else {
    return ''
  }
}

export function useMsgValidAddress(count: number, total: number) {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (count && !loadingManualAddressingFlag) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage(
          { defaultMessage: '{count}/{total} Valid recipients', description: 'Shows total number of Okay recipients. E.g. 3 Okay' },
          { count, total },
        )
      : intl.formatMessage(
          { defaultMessage: '{count}/{total} Valid contacts', description: 'Shows total number of Okay addresses. E.g. 3 Okay' },
          { count, total },
        )
  } else {
    return ''
  }
}

export function useMsgFixedInvalids(count: number) {
  const intl = useIntl()
  if (count) {
    return intl.formatMessage(
      { defaultMessage: '{count} Addresses corrected', description: 'Shows total number of fixed invalid addresses, e.g. 3 Addresses corrected' },
      { count },
    )
  } else {
    return ''
  }
}

export function useMsgFixedDuplicates(count: number) {
  const intl = useIntl()
  if (count) {
    return intl.formatMessage(
      { defaultMessage: '{count} Duplicates removed', description: 'Shows total number of fixed duplicate addresses, e.g. 3 Duplicates removed' },
      { count },
    )
  } else {
    return ''
  }
}

export function useMsgMoreEnvelopes(qty: number, valid: number) {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (qty && valid && !loadingManualAddressingFlag) {
    if (manualAddressingFeatureToggle) {
      return intl.formatMessage(
        {
          defaultMessage:
            'The number of envelopes ({qty}) in your order exceeds your uploaded recipient list ({valid}). Excess envelopes will be sent without recipient addressing.',
          description: 'Show quantity and valid address discrepancy',
        },
        { qty, valid },
      )
    }
    return intl.formatMessage(
      {
        defaultMessage: 'The number of envelopes ({qty}) in your order exceeds your uploaded recipient list ({valid}). Excess envelopes will be sent blank.',
        description: 'Show quantity and valid address discrepancy',
      },
      { qty, valid },
    )
  } else {
    return ''
  }
}

export function useNeedMoreEnvelopesMsg(addressesInList: number, selectedEnvelopesQty: number) {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (loadingManualAddressingFlag) {
    return ''
  }

  if (addressesInList === selectedEnvelopesQty) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage(
          {
            defaultMessage: '{addressesInList}/{selectedEnvelopesQty} recipients will be printed.',
            description: 'Show the number of recipients to be printed',
          },
          { addressesInList, selectedEnvelopesQty },
        )
      : intl.formatMessage(
          {
            defaultMessage: '{addressesInList}/{selectedEnvelopesQty} contacts will be printed.',
            description: 'Show the number of contacts to be printed',
          },
          { addressesInList, selectedEnvelopesQty },
        )
  } else if (addressesInList < selectedEnvelopesQty) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage(
          {
            defaultMessage: '{addressesInList}/{selectedEnvelopesQty} recipients will be printed. Excess envelopes will be sent without recipient addressing.',
            description: 'Show the number of recipients to be printed',
          },
          { addressesInList, selectedEnvelopesQty },
        )
      : intl.formatMessage(
          {
            defaultMessage: '{addressesInList}/{selectedEnvelopesQty} contacts will be printed. Excess envelopes will be sent blank.',
            description: 'Show the number of contacts to be printed',
          },
          { addressesInList, selectedEnvelopesQty },
        )
  } else if (addressesInList > selectedEnvelopesQty) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage(
          {
            defaultMessage:
              'The number of recipients in your list ({addressesInList}) is more than the number of envelopes in you order. Adjust your recipient list or add more envelopes before adding to cart.',
            description: 'Show the number of recipients to be printed',
          },
          { addressesInList },
        )
      : intl.formatMessage(
          {
            defaultMessage:
              'The number of contacts in your list ({addressesInList}) is more than the number of envelopes in you order. Adjust your contact list or add more envelopes before adding to cart.',
            description: 'Show the number of contacts to be printed',
          },
          { addressesInList },
        )
  } else {
    return ''
  }
}

export function useNeedMoreEnvelopesTitle(addressesInList: number, selectedEnvelopesQty: number) {
  const intl = useIntl()
  if (addressesInList < selectedEnvelopesQty) {
    return intl.formatMessage({
      defaultMessage: 'Almost done!',
      description: 'Title for modal to update envelopes quantity',
    })
  } else if (addressesInList > selectedEnvelopesQty) {
    return intl.formatMessage({
      defaultMessage: 'Need more envelopes?',
      description: 'Title for modal to update envelopes quantity',
    })
  } else {
    return intl.formatMessage({
      defaultMessage: 'More recipients?',
      description: 'Title for modal to update envelopes quantity',
    })
  }
}

export function useNeedMoreEnvelopesModalContent() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Need more envelopes?', description: 'Dialog to update envelopes quantity' })
}

export function useNeedMoreEnvelopesModalMsg(addressesInList: number, selectedEnvelopesQty: number) {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (loadingManualAddressingFlag) {
    return ''
  }

  if (addressesInList === selectedEnvelopesQty) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage({
          defaultMessage:
            'The number of recipients in your list already matches the number of envelopes in your order. Add more envelopes below to add new recipients.',
          description: 'Add more envelopes below to add new recipients.',
        })
      : intl.formatMessage(
          {
            defaultMessage: '{addressesInList}/{selectedEnvelopesQty} contacts will be printed.',
            description: 'Show the number of contacts to be printed',
          },
          { addressesInList, selectedEnvelopesQty },
        )
  } else if (addressesInList < selectedEnvelopesQty) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage(
          {
            defaultMessage: '{addressesInList}/{selectedEnvelopesQty} recipients will be printed. Excess envelopes will be sent without recipient addressing.',
            description: 'Show the number of recipients to be printed',
          },
          { addressesInList, selectedEnvelopesQty },
        )
      : intl.formatMessage(
          {
            defaultMessage: '{addressesInList}/{selectedEnvelopesQty} contacts will be printed. Excess envelopes will be sent blank.',
            description: 'Show the number of contacts to be printed',
          },
          { addressesInList, selectedEnvelopesQty },
        )
  } else if (addressesInList > selectedEnvelopesQty) {
    return manualAddressingFeatureToggle
      ? intl.formatMessage(
          {
            defaultMessage:
              'The number of recipients in your list ({addressesInList}) is more than the number of envelopes in you order. Adjust your recipient list or add more envelopes before adding to cart.',
            description: 'Show the number of recipients to be printed',
          },
          { addressesInList },
        )
      : intl.formatMessage(
          {
            defaultMessage:
              'The number of contacts in your list ({addressesInList}) is more than the number of envelopes in you order. Adjust your contact list or add more envelopes before adding to cart.',
            description: 'Show the number of contacts to be printed',
          },
          { addressesInList },
        )
  } else {
    return ''
  }
}
