import { Button, Icon } from '@vp/swan'
import { useIntl } from 'react-intl'
import { FC, useEffect, useState } from 'react'
import { AddressesForPreviewButtons, PreviewType } from 'modules/review/types/address-preview.types'
import classes from 'components/address-form/preview-slider/preview-slide.module.scss'
import clsx from 'clsx'
import { useAddressList } from 'lib/address-list'
import { useAddressPreview } from 'modules/review/contexts/address-preview.context'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { addressesInArray } from 'utilities/address.utils'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
const PreviewSlide: FC<{
  previewType: PreviewType
  actionAddress: { address: IAddressDetails | undefined; type: AddressType }
}> = ({ previewType, actionAddress }) => {
  const { data: addresses } = useAddressList()
  const { onPreview } = useAddressPreview()
  const intl = useIntl()
  const previewRecipientMessage = intl.formatMessage({ defaultMessage: 'Preview recipient', description: 'Title for the recipient address to be previewed' })
  const buttonStyle = clsx({
    [classes.leftButtonStyle]: previewType === PreviewType.PREVIOUS,
    [classes.rightButtonStyle]: previewType === PreviewType.NEXT,
  })
  let disabled = false
  if (actionAddress.address === undefined) disabled = true

  if (addresses?.addressIds && addresses?.addressIds?.length < 2) {
    return <></>
  }

  const preview = () => {
    const { address, type } = actionAddress
    if (onPreview && address) {
      onPreview({ address: { ...address } as IAddressDetails, rowIndex: String(address.rowIndex), addressType: type })
    }
  }

  return (
    <>
      <div className={buttonStyle}>
        {preview && (
          <Button aria-label={previewRecipientMessage} buttonShape="round" size="standard" disabled={disabled} onClick={preview}>
            <Icon iconType={previewType === PreviewType.PREVIOUS ? 'chevronLeft' : 'chevronRight'} />
          </Button>
        )}
      </div>
    </>
  )
}

export const PreviewArrowButtons: FC = () => {
  const { actionConfig } = useAddressPreview()
  const { data: addresses } = useAddressList()
  const [addressesForButtons, setAddressesForButtons] = useState<AddressesForPreviewButtons>({
    current: undefined,
    next: undefined,
    previous: undefined,
    addressType: '' as AddressType,
  })

  useEffect(() => {
    if (actionConfig?.rowIndex) {
      const currentNextPrevAddresses = addressesInArray({ addresses: addresses as UploadedAddressesResponse, rowIndex: actionConfig.rowIndex })
      setAddressesForButtons({ ...currentNextPrevAddresses })
    }
  }, [actionConfig.rowIndex, addresses])

  return (
    <>
      <PreviewSlide previewType={PreviewType.PREVIOUS} actionAddress={{ address: addressesForButtons.previous, type: addressesForButtons.addressType }} />
      <PreviewSlide previewType={PreviewType.NEXT} actionAddress={{ address: addressesForButtons.next, type: addressesForButtons.addressType }} />
    </>
  )
}
