import { FlexBox, ModalDialog, ModalDialogCloseButton, ModalDialogContent, ModalDialogHeader, ModalDialogNav, ModalDialogTitle, Typography } from '@vp/swan'
import { SectionLoaderWrapper } from 'components/loader/loader.component'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useAddressList } from 'lib/address-list'
import { useAddressListAdd } from 'lib/address-list/address-book.hooks'
import { withErrorBoundary } from 'lib/errors'
import { useMsgAddressAddInformation } from 'lib/intl/msg-address-dialog.hooks'
import { useMsgAddContactSuccess, useMsgAddRecipientSuccess } from 'lib/intl/msg-add-action.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useToastIncorrectAddress, useToastPositive } from 'lib/toast/toast.hooks'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { RowAddressActionParams } from 'modules/review/types/address-multi-selection.types'
import { FC, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UseMutateFunction } from 'react-query'
import { UpdateAddressRequest, UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { SingleRowEdit as AddAddressForm } from '../modal-edit/single-row-edit.component'
import { EditableAddress, getEditableAddresses } from './add-address.util'
import { FORM_TYPES } from 'components/address-form/address-form.constants'
import { useProductInfoQtySelection } from 'lib/product-info'
import classes from './add-address.module.scss'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

const AddAddressesInternal: FC<
  Omit<RowAddressActionParams, 'isOpen' | 'type' | 'ids'> & {
    update: UseMutateFunction<UploadedAddressesResponse, unknown, { request: UpdateAddressRequest }, unknown>
    inProgress: boolean
    hasError: boolean
    onExit: () => void
  }
> = ({ savedListFlow, onExit, update, inProgress, hasError }) => {
  const { data: addresses } = useAddressList()
  const [addressesToAdd, setAddressToAdd] = useState<EditableAddress[]>(getEditableAddresses(addresses))
  const [hasToReset, setHasToReset] = useState<boolean>(false)
  const { selectedQty: selectedEnvelopes } = useProductInfoQtySelection()
  const addRecipientSuccess = useMsgAddRecipientSuccess()
  const successMessage = useMsgAddContactSuccess()
  const positiveToast = useToastPositive()
  const scrollRef = useRef<HTMLInputElement>(null)

  const saveAddress = (address: IAddressDetails) => {
    setHasToReset(false)
    const updateAddressRequestBody: UpdateAddressRequest = {
      addressesToUpdate: [address],
      addressIndexesToRemove: [],
      duplicatedAddressIndexesToPromoteAsValid: [],
    }
    update(
      { request: updateAddressRequestBody },
      {
        onSuccess: () => {
          if (savedListFlow) {
            positiveToast(addRecipientSuccess, 'mini')
            setAddressToAdd(getEditableAddresses(addresses))
          } else {
            positiveToast(successMessage, 'mini')
            onExit()
          }
          setHasToReset(true)
        },
      },
    )
    scrollRef?.current?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
  }

  useEffect(() => {
    setAddressToAdd(getEditableAddresses(addresses))
  }, [addresses])

  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  return (
    <FlexBox ref={scrollRef} flexDirection="column" style={{ height: 'calc(100% - 64px)' }}>
      {addressesToAdd.length ? (
        <>
          <ModalDialogHeader mb={5}>
            <Typography textColor="subtle" fontSize={'small'} pb={1}>
              {savedListFlow && !loadingManualAddressingFlag && manualAddressingFeatureToggle && (
                <FormattedMessage
                  defaultMessage="Recipient {recipientNumber} of {totalEnvelopesSelected}"
                  description="Recipient number being added"
                  values={{ recipientNumber: (addresses?.addressIds?.length || 0) + 1, totalEnvelopesSelected: selectedEnvelopes }}
                />
              )}
            </Typography>
            <ModalDialogTitle>
              {!loadingManualAddressingFlag &&
                (manualAddressingFeatureToggle ? (
                  <FormattedMessage defaultMessage="Add recipient" description="Title for the recipient address to be added" />
                ) : (
                  <FormattedMessage defaultMessage="Add contact" description="Title for the recipient address to be added" />
                ))}
            </ModalDialogTitle>
          </ModalDialogHeader>
          <AddAddressForm
            formAdd={true}
            newAddress={true}
            savedListFlow={savedListFlow}
            formType={FORM_TYPES.ADDRESS}
            editInProgress={inProgress}
            suggestion={addressesToAdd[0].suggested}
            address={addressesToAdd[0].toEdit}
            onUpdate={saveAddress}
            showError={hasError}
            onExit={onExit}
            hasToReset={hasToReset}
          />
        </>
      ) : null}
    </FlexBox>
  )
}

const AddContainer: FC<Omit<RowAddressActionParams, 'type' | 'ids'>> = ({ isOpen, savedListFlow, onExit }) => {
  const {
    isEnabled,
    mutationResult: { mutate, isLoading, isError },
    errorFlag,
  } = useAddressListAdd(false, false)
  const unknownErrorToast = useToastSomethingWentWrong()
  const incorrectAddressToast = useToastIncorrectAddress()
  const addressAddInformation = useMsgAddressAddInformation()
  const modalCloseLabel = useMsgModalClose()
  const workId = useWorkIdQueryParam()
  const { logWarn } = useLogger()

  useEffect(() => {
    if (isError) {
      if (errorFlag) {
        incorrectAddressToast('mini')
      } else unknownErrorToast('mini')
      logWarn('Error occurred while adding addresses', { contextData: { workId } })
    }
  }, [isError, unknownErrorToast, workId, logWarn])

  return (
    <ModalDialog variant="panel-right" isOpen={isOpen} onRequestDismiss={onExit} className="custom-panel-capped">
      <ModalDialogContent aria-labelledby={addressAddInformation} backgroundColor="standard" style={{ width: 450 }}>
        <SectionLoaderWrapper showLoader={isLoading || !isEnabled} className={classes.addAddressLoader}>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} onClick={onExit} />
          </ModalDialogNav>
          <AddAddressesInternal onExit={onExit} savedListFlow={savedListFlow} update={mutate} inProgress={isLoading} hasError={isError} />
        </SectionLoaderWrapper>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export const AddAddress = withErrorBoundary(AddContainer)
