import { useIntl } from 'react-intl'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

export type Config = {
  key: string
  label: string
  isOptional: boolean
}

export function useFieldConfig(configFor: string): Config[] {
  const intl = useIntl()

  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  const isManualAddressing = !loadingManualAddressingFlag && manualAddressingFeatureToggle

  const addressFormConfig = [
    {
      key: 'lastName',
      label: intl.formatMessage({ defaultMessage: 'Name 1' }),
      isOptional: false,
    },
    {
      key: 'company',
      label: intl.formatMessage({ defaultMessage: 'Name 2 or Company (optional)' }),
      isOptional: true,
    },
    {
      key: 'streetName',
      label: intl.formatMessage({ defaultMessage: 'Address' }),
      isOptional: false,
    },
    {
      key: 'city',
      label: intl.formatMessage({ defaultMessage: 'City/Town' }),
      isOptional: false,
    },
    {
      key: 'state',
      label: intl.formatMessage({ defaultMessage: 'State' }),
      isOptional: false,
    },
    {
      key: 'postalCode',
      label: intl.formatMessage({ defaultMessage: 'Zip Code' }),
      isOptional: false,
    },
  ]
  let mailingListConfig = [
    {
      key: 'mailingListName',
      label: intl.formatMessage({ defaultMessage: 'Name of recipient list' }),
      isOptional: false,
    },
  ]

  if (isManualAddressing) {
    mailingListConfig = [
      {
        key: 'mailingListName',
        label: intl.formatMessage({ defaultMessage: 'Name of address list' }),
        isOptional: false,
      },
    ]
  }

  let fieldConfig: { key: string; label: string; isOptional: boolean }[] = []
  switch (configFor) {
    case 'address-form':
      fieldConfig = [...addressFormConfig]
      break
    case 'new-mailing-list-form':
      fieldConfig = [...mailingListConfig]
      break
  }

  return fieldConfig
}
