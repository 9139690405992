import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

export function useMsgEditLabel() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Edit', description: 'Button label to edit an address' })
}

export function useMsgPreviewLabel() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Preview', description: 'Button label to preview an address' })
}

export function useMsgEditValidAddress(count: number) {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  return intl.formatMessage(
    {
      defaultMessage: 'Edit selected okay({count})',
      description: 'Label for taking edit action on selected valid addresses with address count e.g. Edit selected valid(6)',
    },
    { count, validLabel: isPostcard ? 'valid' : 'okay' },
  )
}

export function useMsgEditErrorAddresses(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: 'Edit selected error (1)',
        description: 'Label for taking edit action on selected invalid addresses with address count 1 e.g. Edit selected error(1)',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: 'Edit selected errors({count})',
          description: 'Label for taking edit action on selected invalid addresses with address count more than 1 e.g. Edit selected errors(6)',
        },
        { count },
      )
    default:
      return ''
  }
}

export function useMsgEditDuplicateAddresses(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: 'Edit selected duplicate (1)',
        description: 'Label for taking edit action on selected duplicate addresses with address count 1 e.g. Edit duplicate error(1)',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: 'Edit selected duplicates({count})',
          description: 'Label for taking edit action on selected duplicate addresses with address count more than 1 e.g. Edit selected errors(6)',
        },
        { count },
      )
    default:
      return ''
  }
}

export function useMsgEditRecipients() {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  if (loadingManualAddressingFlag) {
    return ''
  }

  return manualAddressingFeatureToggle
    ? intl.formatMessage({
        defaultMessage: 'Recipient successfully edited.',
        description: 'Recipient edited confirmation',
      })
    : intl.formatMessage({
        defaultMessage: 'Contact successfully edited.',
        description: 'Contact edited confirmation',
      })
}

export function useMsgEditRecipientsValidation() {
  const intl = useIntl()
  return function (addressFieldName: string): Record<string, string> {
    return {
      invalidAddressEntryEmpty: intl.formatMessage(
        {
          defaultMessage: '{addressFieldName} is required.',
        },
        { addressFieldName },
      ),
      invalidAddressEntryLength: intl.formatMessage(
        {
          defaultMessage: '{addressFieldName} exceeds character limit of 33.',
        },
        { addressFieldName },
      ),
      invalidAddressEntryLengthExtended: intl.formatMessage(
        {
          defaultMessage: '{addressFieldName} exceeds character limit of 40.',
        },
        { addressFieldName },
      ),
      invalidPostalCode: intl.formatMessage(
        {
          defaultMessage: 'The provided {addressFieldName} is invalid.',
        },
        { addressFieldName },
      ),
      invalidAddressEntryCharacters: intl.formatMessage(
        {
          defaultMessage: '{addressFieldName} contains characters that are not permitted.',
        },
        { addressFieldName },
      ),
    }
  }
}
export function useMsgAddRecipients() {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (loadingManualAddressingFlag) {
    return ''
  }

  return manualAddressingFeatureToggle
    ? intl.formatMessage({
        defaultMessage: 'Recipient successfully added.',
        description: 'Recipient added confirmation',
      })
    : intl.formatMessage({
        defaultMessage: 'Contact successfully added.',
        description: 'Contact added confirmation',
      })
}

export function useMsgRenameSavedList() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Address list was successfully renamed.',
    description: 'Address list renamed confirmation',
  })
}
