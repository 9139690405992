import { AddressWithSuggestion, DuplicatedAddressGroup, IAddressDetails } from 'modules/review/types/address-list.types'
import { UpdateAddressRequest, UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { getIdForAddressDetail } from './address.utils'

export function returnIfValidRequestBody({ addressesToUpdate, addressIndexesToRemove, duplicatedAddressIndexesToPromoteAsValid }: UpdateAddressRequest) {
  if (addressesToUpdate.length || addressIndexesToRemove.length || duplicatedAddressIndexesToPromoteAsValid.length) {
    return {
      addressesToUpdate,
      addressIndexesToRemove,
      duplicatedAddressIndexesToPromoteAsValid,
    } as UpdateAddressRequest
  }
  return null
}

export function getInitialAddressUpdateRequest(): UpdateAddressRequest {
  return {
    addressesToUpdate: [],
    addressIndexesToRemove: [],
    duplicatedAddressIndexesToPromoteAsValid: [],
  }
}

/**
 *
 * @param addresses
 * @returns
 *
 * Invalid individual addresses
 *  has suggested? replace with suggested and add to addressesToUpdate : add to addressIndexesToRemove
 * Duplicated group
 *  Invalid has suggested address?
 *    - Yes > same person? replace suggested address for the first address and add it to the addressesToUpdate, then add the rest to addressIndexesToRemove
 *            different person? replace suggested address for all addresses and add it all to addressesToUpdate
 *    - No  > add all addresses to addressIndexesToRemove
 *  Valid, same person? keep first and the rest to addressIndexesToRemove : add all to duplicatedAddressIndexesToPromoteAsValid
 */
export function getAddressUpdateRequestForAutoFix(
  _currentResidents: { firstName: string; lastName: string },
  addresses?: UploadedAddressesResponse | null,
): UpdateAddressRequest | null {
  let duplicatedAddressIndexesToPromoteAsValid: number[] = []
  const addressesToUpdate: IAddressDetails[] = []
  const addressIndexesToRemove: number[] = []

  const { addressIndexesToRemoveForIndividual, addressesToUpdateForIndividual } = processInvalidIndividualAddresses(addresses?.invalidAddresses)
  addressIndexesToRemove.push(...addressIndexesToRemoveForIndividual)
  addressesToUpdate.push(...addressesToUpdateForIndividual)

  addresses?.duplicatedAddressGroups?.forEach(duplicateGroup => {
    if (!duplicateGroup.isValid) {
      const { addressIndexesToRemoveForDuplicatedGroup, addressesToUpdateForDuplicatedGroup } = processInvalidDuplicatedGroupAddresses(duplicateGroup)
      addressIndexesToRemove.push(...addressIndexesToRemoveForDuplicatedGroup)
      addressesToUpdate.push(...addressesToUpdateForDuplicatedGroup)
    } else {
      if (duplicateGroup.samePerson) {
        const toRemove: IAddressDetails[] = duplicateGroup.addresses.slice(1)
        addressIndexesToRemove.push(...toRemove.map(address => address.rowIndex))
      } else {
        duplicatedAddressIndexesToPromoteAsValid.push(...duplicateGroup.addresses.map(address => address.rowIndex))
      }
    }
  })

  duplicatedAddressIndexesToPromoteAsValid = duplicatedAddressIndexesToPromoteAsValid.filter(d => !addressIndexesToRemove.includes(d))
  return returnIfValidRequestBody({
    addressesToUpdate,
    addressIndexesToRemove,
    duplicatedAddressIndexesToPromoteAsValid,
  })
}

function processInvalidIndividualAddresses(addresses?: AddressWithSuggestion[]) {
  const addressesToUpdateForIndividual: IAddressDetails[] = []
  const addressIndexesToRemoveForIndividual: number[] = []

  addresses?.forEach(address => {
    if (address.suggestedAddress) {
      addressesToUpdateForIndividual.push(address.suggestedAddress)
    } else {
      addressIndexesToRemoveForIndividual.push(address.originalAddress.rowIndex)
    }
  })

  return {
    addressesToUpdateForIndividual,
    addressIndexesToRemoveForIndividual,
  }
}

/***
 * group has suggested address?
 *  - Yes > same person? replace suggested address for the first address and add it to the addressesToUpdate, then add the rest to addressIndexesToRemove
 *          different person? replace suggested address for all addresses and add it all to addressesToUpdate
 *  - No  > add all addresses to addressIndexesToRemove
 */
function processInvalidDuplicatedGroupAddresses(duplicateGroup: DuplicatedAddressGroup) {
  const addressesToUpdateForDuplicatedGroup: IAddressDetails[] = []
  const addressIndexesToRemoveForDuplicatedGroup: number[] = []
  if (duplicateGroup.suggestedAddress) {
    if (duplicateGroup.samePerson) {
      addressesToUpdateForDuplicatedGroup.push(replaceAddressWithSuggestedOne(duplicateGroup.addresses[0], duplicateGroup.suggestedAddress))
      const toRemove: IAddressDetails[] = duplicateGroup.addresses.slice(1)
      addressIndexesToRemoveForDuplicatedGroup.push(...toRemove.map(address => address.rowIndex))
    } else {
      duplicateGroup.addresses?.forEach(address => {
        addressesToUpdateForDuplicatedGroup.push(replaceAddressWithSuggestedOne(address, duplicateGroup.suggestedAddress))
      })
    }
  } else {
    addressIndexesToRemoveForDuplicatedGroup.push(...duplicateGroup.addresses.map(address => address.rowIndex))
  }

  return {
    addressesToUpdateForDuplicatedGroup,
    addressIndexesToRemoveForDuplicatedGroup,
  }
}

function replaceAddressWithSuggestedOne(address: IAddressDetails, suggestedAddress?: IAddressDetails) {
  return {
    ...suggestedAddress,
    firstName: address.firstName,
    lastName: address.lastName,
    rowIndex: address.rowIndex,
    company: address.company,
    companyName: address.companyName,
    middleName: address.middleName,
    salutation: address.salutation,
    suffix: address.suffix,
    title: address.title,
  } as IAddressDetails
}

export function getAddressRequestForMerge(duplicateGroup?: DuplicatedAddressGroup) {
  if (!duplicateGroup) return null
  const addressIndexesToRemove: number[] = []
  const duplicatedAddressIndexesToPromoteAsValid: number[] = []
  duplicateGroup.addresses.forEach((address: IAddressDetails, index) => {
    if (index === 0) {
      duplicatedAddressIndexesToPromoteAsValid.push(address.rowIndex)
    } else {
      addressIndexesToRemove.push(address.rowIndex)
    }
  })

  return returnIfValidRequestBody({
    addressesToUpdate: [],
    addressIndexesToRemove,
    duplicatedAddressIndexesToPromoteAsValid,
  })
}

export function getAddressRequestForSelect(
  selection: Record<string, boolean>,
  { firstName, lastName, currentResidentId }: { firstName: string; lastName: string; currentResidentId: string },
  duplicateGroup?: DuplicatedAddressGroup | null,
) {
  if (!duplicateGroup) return null
  const addressesToUpdate: IAddressDetails[] = []
  const addressIndexesToRemove: number[] = []
  const duplicatedAddressIndexesToPromoteAsValid: number[] = []

  duplicateGroup?.addresses.forEach((address: IAddressDetails) => {
    const addressId = getIdForAddressDetail(address)
    if (selection[addressId]) {
      duplicatedAddressIndexesToPromoteAsValid.push(address.rowIndex)
    } else {
      addressIndexesToRemove.push(address.rowIndex)
    }
  })

  if (selection[currentResidentId]) {
    const reUsableRowIdx = addressIndexesToRemove.pop()
    const address = duplicateGroup.addresses.find(u => u.rowIndex === reUsableRowIdx)
    if (address) {
      addressesToUpdate.push({
        ...address,
        firstName,
        lastName,
      })
    }
  }
  return returnIfValidRequestBody({
    addressesToUpdate,
    addressIndexesToRemove,
    duplicatedAddressIndexesToPromoteAsValid,
  })
}

export function getAddressAutoPromoteRequest(addresses?: UploadedAddressesResponse | null): UpdateAddressRequest | null {
  if (!addresses?.duplicatedAddressGroups || addresses.duplicatedAddressGroups.length === 0) return null

  const addressIndexesToRemove: number[] = []
  const duplicatedAddressIndexesToPromoteAsValid: number[] = []
  addresses.duplicatedAddressGroups.forEach((duplicateGroup: DuplicatedAddressGroup) => {
    duplicateGroup.addresses.forEach((address: IAddressDetails, index) => {
      if (index === 0 || !duplicateGroup.samePerson) {
        duplicatedAddressIndexesToPromoteAsValid.push(address.rowIndex)
      } else {
        addressIndexesToRemove.push(address.rowIndex)
      }
    })
  })

  return returnIfValidRequestBody({
    addressesToUpdate: [],
    addressIndexesToRemove,
    duplicatedAddressIndexesToPromoteAsValid,
  })
}
