import { useState, useCallback, useEffect } from 'react'
import { Icon, Box, Button } from '@vp/swan'
import { useMailingListIdQueryParam } from 'contexts/query-param.context'
import { useAccessToken, useShopperId } from 'lib/user'
import { EditFilename } from 'modules/review/components/modal-edit-filename/edit-filename.component'
import { useToastPositive, useErrorToastWithDuration } from 'lib/toast/toast.hooks'
import { useMsgRenameSavedList } from 'lib/intl/msg-edit-action.hooks'
import { useMsgRenameSavedListError, useMsgSomeError } from 'lib/intl/msg-errors.hooks'
import { useMailingListUpdate } from 'lib/address-list'
import { Ellipsis } from 'components/ellipsis/ellipsis.component'
import classes from './page-layout.module.scss'
import { PageName, useEventTracking } from 'lib/telemetry'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { AdditionalTrackingDataType, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useLogger } from '@vp/shared-capabilities-component-library/components'

const FileNameEditor = ({ initialFileName }: { initialFileName: string }) => {
  const [showEditFilenameModal, setShowEditFilenameModal] = useState(false)
  const [displayedFileName, setDisplayedFileName] = useState(initialFileName)
  const mailingListId = useMailingListIdQueryParam()
  const token = useAccessToken()
  const shopperId = useShopperId()
  const positiveToast = useToastPositive()
  const successMessage = useMsgRenameSavedList()
  const errorMessage = useMsgRenameSavedListError()
  const errorToast = useErrorToastWithDuration(errorMessage)
  const failureMessage = useMsgSomeError()
  const failureToast = useErrorToastWithDuration(failureMessage)

  const { logError, logInfo } = useLogger()

  const fireTracking = useEventTracking()
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)

  const flyOutTrackingData: AdditionalTrackingDataType = {
    category: TrackingCategoryType.FLY_OUT,
    label: TrackingLabelType.FLY_OUT_SELECTION,
    pageName: trackingProductPageName,
  }

  const toggleEditFilenameModal = useCallback(() => {
    setShowEditFilenameModal(prevState => !prevState)
    fireTracking(TrackingEventType.FLY_OUT_VIEWED, flyOutTrackingData)
  }, [])

  const {
    mutationResult: { mutateAsync: mutateMailingList },
  } = useMailingListUpdate(mailingListId)

  const updateMailingList = async (fileName: string | undefined) => {
    mutateMailingList({
      request: {
        name: fileName ? fileName : '',
      },
    })
      .then(response => {
        logInfo(`Filename updated successfully`, { fileName: response.name })
        setDisplayedFileName(response.name)
        positiveToast(successMessage)
        toggleEditFilenameModal()
      })
      .catch((error: any) => {
        logError('Error updating filename', error)
        errorToast()
      })
  }

  const handleSave = async (fileName: string | undefined) => {
    if (mailingListId && token && shopperId) {
      try {
        void updateMailingList(fileName)
      } catch (error) {
        logError('Error calling API', { contextData: { error } })
        failureToast()
      }
    }
  }

  useEffect(() => {
    setDisplayedFileName(initialFileName)
  }, [initialFileName])

  return (
    <>
      <Box fontWeight="bold" className={classes.filename}>
        <Ellipsis title={displayedFileName} fontSize={'standard'} />
      </Box>
      {mailingListId && (
        <Button skin="unstyled" ml={2} onClick={toggleEditFilenameModal}>
          <Icon iconType="edit" size={'16p'} style={{ marginTop: '-6px' }} />
        </Button>
      )}
      <EditFilename isOpen={showEditFilenameModal} onClose={toggleEditFilenameModal} handleSave={handleSave} initialFileName={initialFileName} />
    </>
  )
}

export default FileNameEditor
