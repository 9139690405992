import { useIntl } from 'react-intl'

export function useMsgAddContactSuccess() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Contact successfully added.',
    description: 'Contact added confirmation',
  })
}

export function useMsgAddRecipientSuccess() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'One recipient successfully added to the list',
    description: 'Recipient added confirmation',
  })
}

export function useMsgAddRecipient() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Add recipient',
    description: 'Label to add recipient',
  })
}
