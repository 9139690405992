import { IAddressDetails } from 'modules/review/types/address-list.types'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'

export type EditableAddress = {
  original: IAddressDetails
  toEdit: IAddressDetails
  suggested?: IAddressDetails
}

export function getEditableAddresses(addRes: UploadedAddressesResponse | null | undefined): EditableAddress[] {
  const editableAddresses: EditableAddress[] = []
  if (addRes) {
    const address: IAddressDetails = {
      rowIndex: 0,
      salutation: '',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      title: '',
      company: '',
      addressLine1: '',
      addressLine2: '',
      zipCode: undefined,
      city: '',
      streetName: '',
      postalCode: '',
      country: '',
      state: '',
    }
    editableAddresses.push({
      original: address,
      toEdit: { ...address },
    })
  }
  return editableAddresses
}
