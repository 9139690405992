import {
  Button,
  ModalDialogBody,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogTitle,
  SelectedValues,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  Typography,
} from '@vp/swan'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useAddressList } from 'lib/address-list'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { useToastSomethingWentWrong } from 'lib/toast'
import { AddressDisplayWithFallback } from 'modules/review/components/address-display-with-fallback/address-display-with-fallback.component'
import { SaveAndContinueBtn } from 'modules/review/components/modal-edit/save-continue-btn.component'
import { DuplicatedAddressGroup, IAddressDetails } from 'modules/review/types/address-list.types'
import { getInitialAddressUpdateRequest, returnIfValidRequestBody } from 'modules/review/utilities/update-request.utils'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UseMutateFunction } from 'react-query'
import { UpdateAddressRequest, UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import classes from './post-edit.module.scss'

const PostEditSelection: FC<{
  onSave: (option: { idsToDelete: number[]; idsToPromote: number[] }) => void
  hasNext: boolean
  group: DuplicatedAddressGroup
}> = ({ onSave, group, hasNext }) => {
  const [selectedValues, onSelectedValuesChange] = useState<SelectedValues>({})
  const saveAndContinue = () => {
    const idsToDelete: number[] = []
    const idsToPromote: number[] = []
    group.addresses.forEach(address => {
      if (selectedValues[address.rowIndex]) {
        idsToPromote.push(address.rowIndex)
      } else {
        idsToDelete.push(address.rowIndex)
      }
    })
    onSave({ idsToDelete, idsToPromote })
  }
  const skipAndContinue = () => {
    onSave({ idsToDelete: [], idsToPromote: [] })
  }
  const disabled = !Object.values(selectedValues).filter(Boolean).length

  return (
    <>
      <ModalDialogBody>
        <SelectionSet mt={6} variant="multi-select" selectedValues={selectedValues} onSelectedValuesChange={onSelectedValuesChange}>
          {group.addresses.map((address: IAddressDetails) => (
            <SelectionSetInput value={String(address.rowIndex)} key={address.rowIndex}>
              <SelectionSetLabel>
                <AddressDisplayWithFallback address={address} />
              </SelectionSetLabel>
            </SelectionSetInput>
          ))}
        </SelectionSet>
      </ModalDialogBody>

      <ModalDialogFooter pinned className={classes.footer}>
        <SaveAndContinueBtn hasNext={hasNext} onClick={saveAndContinue} disabled={disabled} />
        <Button width="full-width" skin="unstyled" onClick={skipAndContinue} className={classes.underline}>
          <FormattedMessage defaultMessage="Skip and continue" />
        </Button>
      </ModalDialogFooter>
    </>
  )
}

export const PostEdit: FC<{
  ids: number[]
  onExit: () => void
  update: UseMutateFunction<UploadedAddressesResponse, unknown, { request: UpdateAddressRequest }, unknown>
}> = ({ ids, onExit, update }) => {
  const { data: addressList } = useAddressList()
  const [currentIdx, setCurrentIdx] = useState(0)
  const [request, setRequestObject] = useState<UpdateAddressRequest>(() => getInitialAddressUpdateRequest())
  const unknownErrorToast = useToastSomethingWentWrong()
  const workId = useWorkIdQueryParam()
  const { logError, logWarn } = useLogger()

  const solveDuplicates = addressList?.duplicatedAddressGroups?.filter(group => group.addresses.some(address => ids.includes(address.rowIndex)))

  useEffect(() => {
    if (solveDuplicates && solveDuplicates.length === 0) {
      onExit()
    }
  }, [solveDuplicates, onExit])
  const onSave = ({ idsToDelete, idsToPromote }: { idsToDelete: number[]; idsToPromote: number[] }) => {
    if (!solveDuplicates || solveDuplicates.length === 0) return undefined
    const nextRequest = {
      addressesToUpdate: [],
      addressIndexesToRemove: [...request.addressIndexesToRemove, ...idsToDelete],
      duplicatedAddressIndexesToPromoteAsValid: [...request.duplicatedAddressIndexesToPromoteAsValid, ...idsToPromote],
    }
    setRequestObject(nextRequest)
    if (currentIdx < solveDuplicates.length - 1) {
      setCurrentIdx(i => i + 1)
      return undefined
    }
    const validRequest = returnIfValidRequestBody(nextRequest)
    if (!validRequest) {
      logWarn('The request to save duplicate addresses post editing is not valid', { contextData: { workId } })
      onExit()

      return undefined
    }
    update(
      { request: validRequest },
      {
        onError: () => {
          unknownErrorToast()
          logError('Error occurred while saving duplicate address selections post editing addresses', { contextData: { workId } })
        },
        onSettled: () => {
          onExit()
        },
      },
    )
  }
  useEffect(() => {
    setCurrentIdx(0)
    setRequestObject(getInitialAddressUpdateRequest())
  }, [ids])

  if (!solveDuplicates || !solveDuplicates[currentIdx]) return null
  return (
    <>
      <ModalDialogHeader mb={5}>
        <ModalDialogTitle>
          <FormattedMessage defaultMessage="We found another recipient with this address" />
          <Typography fontSize={'small'} fontWeight="bold" mt={4}>
            <FormattedMessage defaultMessage="Select all recipients you would like to keep, we will delete the others." />
          </Typography>
        </ModalDialogTitle>
      </ModalDialogHeader>
      <PostEditSelection onSave={onSave} hasNext={currentIdx < solveDuplicates.length - 1} group={solveDuplicates[currentIdx]} key={currentIdx} />
    </>
  )
}
