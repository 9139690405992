import {
  FlexBox,
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Typography,
  Icon,
} from '@vp/swan'
import { QuantitySelector } from 'components/quantity/quantity-selector.component'
import { withErrorBoundary } from 'lib/errors'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNeedMoreEnvelopesTitle, useNeedMoreEnvelopesModalContent, useNeedMoreEnvelopesModalMsg } from 'lib/intl/msg-validation-header.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import classes from './update-quantity.module.scss'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { PageName, useEventTracking } from 'lib/telemetry'
import { AdditionalTrackingDataType, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'

type UpdateQuantityParams = {
  isOpen: boolean
  onQuantityChange: () => void
  onClose: () => void
  addressesInList: number
  selectedEnvelopesQty: number
  disableATCBtn?: boolean
  onChange: (newQty: string) => void
  newQty: string
}

const UpdateQuantityInternal: FC<UpdateQuantityParams> = ({
  onQuantityChange,
  onClose,
  isOpen,
  addressesInList,
  selectedEnvelopesQty,
  disableATCBtn = false,
  onChange,
  newQty,
}) => {
  const needMoreEnvelopesTitle = useNeedMoreEnvelopesTitle(addressesInList, selectedEnvelopesQty)
  const modalCloseLabel = useMsgModalClose()
  const needMoreEnvelopesContent = useNeedMoreEnvelopesModalContent()
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)
  const fireTracking = useEventTracking()
  const needMoreEnvelopesMsgContent = useNeedMoreEnvelopesModalMsg(addressesInList, selectedEnvelopesQty)

  useEffect(() => {
    if (!isOpen) return

    const flyOutTrackingData: AdditionalTrackingDataType = {
      category: TrackingCategoryType.FLY_OUT,
      label: TrackingLabelType.UPDATE_QUANTITY_FLY_OUT,
      pageName: trackingProductPageName,
    }
    fireTracking(TrackingEventType.EXPERIMENT_EVENT_NAME, flyOutTrackingData)
  }, [isOpen, fireTracking, trackingProductPageName])

  const handleContinue = () => {
    const flyOutTrackingData: AdditionalTrackingDataType = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: TrackingLabelType.ADD_MAILING_LIST,
      pageName: trackingProductPageName,
      eventDetail: 'add_envelope_' + newQty,
    }
    fireTracking(TrackingEventType.LINK_CLICKED, flyOutTrackingData)
    onQuantityChange()
  }

  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={onClose}>
      <ModalDialogContent p={7} aria-labelledby={needMoreEnvelopesContent}>
        <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
        <ModalDialogHeader mb={5}>
          <ModalDialogTitle>{needMoreEnvelopesTitle}</ModalDialogTitle>
        </ModalDialogHeader>

        <ModalDialogBody>
          <FlexBox alignItems="center">
            {selectedEnvelopesQty === addressesInList ? (
              <Icon iconType="success" size="20p" skin="success" />
            ) : (
              <Icon iconType="success" size="20p" skin="warning" />
            )}
            <Typography fontSize={'standard'} textAlign="left" pl={3}>
              <FormattedMessage defaultMessage="{count} Envelopes added" description="Count of envelopes added" values={{ count: selectedEnvelopesQty }} />
            </Typography>
          </FlexBox>
          <FlexBox alignItems="center">
            <Icon iconType="success" size="20p" skin="success" />
            <Typography fontSize={'standard'} textAlign="left" pl={3}>
              <FormattedMessage defaultMessage="{count} Recipients added" description="Count of recipients added" values={{ count: addressesInList }} />
            </Typography>
          </FlexBox>
          <Typography fontSize={'standard'} textAlign="left" pl={1} my={5}>
            {needMoreEnvelopesMsgContent}
          </Typography>
          <QuantitySelector isInModal fullWidth showPrice onChange={onChange} newQty={newQty} />
        </ModalDialogBody>

        <ModalDialogButtons mt={3}>
          <Button skin="primary" width="full-width" onClick={handleContinue} disabled={disableATCBtn}>
            <FormattedMessage defaultMessage="Continue" description="Button label for adding envelopes" />
          </Button>
          <Button
            style={{ textDecoration: 'underline' }}
            skin="link"
            width="full-width"
            onClick={onClose}
            pt={4}
            fontSize="small"
            fontWeight="bold"
            className={classes.goBackButton}
          >
            <FormattedMessage defaultMessage="No, thanks" description="Button label to not opt for adding envelopes" />
          </Button>
        </ModalDialogButtons>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export const UpdateQuantity = withErrorBoundary(UpdateQuantityInternal)
