import { FlexBox, Listbox, ListboxButton, ListboxList, ListboxOption, ListboxPopover, P, Pricing } from '@vp/swan'
import { useProductInfo, useProductInfoQtySelection, useProductInfoUpdate } from 'lib/product-info/product-info.context'
import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DiscountedPrice } from 'components/price/discounted-price.component'

import classes from './quantity-selector.module.scss'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

type QuantitySelectorProps = {
  isInModal?: boolean
  fullWidth?: boolean
  onChange?: (newQty: string) => void
  newQty?: string
  showPrice?: boolean
}

export const QuantitySelector: FC<QuantitySelectorProps> = ({ isInModal = false, fullWidth = false, onChange, newQty, showPrice = false }) => {
  const productInfo = useProductInfo()
  const updateSelection = useProductInfoUpdate()
  const { selectedQty } = useProductInfoQtySelection()
  const quantities = productInfo?.data?.standardConfigurator.quantities.quantities || []

  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const intl = useIntl()

  const updateQuantity = (newQty: string) => {
    updateSelection({
      qty: Number(newQty),
    })
  }
  if (!quantities) return null
  return (
    <Listbox
      mr={4}
      disabled={typeof selectedQty === 'undefined' || !quantities.length}
      value={(selectedQty || newQty || 0).toString()}
      onChange={onChange || updateQuantity}
      fullWidth={fullWidth}
      bgc="standard"
      className={classes.qtySelectorListBox}
    >
      <ListboxButton
        className={classes.qtySelectorListBoxLabel}
        labelPrefix={
          <>
            {!loadingManualAddressingFlag &&
              (manualAddressingFeatureToggle ? (
                <FormattedMessage defaultMessage="Total Quantity" description="Estimated quantity of the envelopes" />
              ) : (
                <FormattedMessage defaultMessage="Quantity" description="Estimated quantity of the envelopes" />
              ))}
          </>
        }
      ></ListboxButton>

      <ListboxPopover inModal={isInModal}>
        <ListboxList>
          {quantities.map(qtyConfig => (
            <ListboxOption
              key={qtyConfig.quantity}
              value={Number(qtyConfig.quantity)}
              textValue={intl.formatMessage(
                {
                  defaultMessage: '{count}',
                  description: 'Count of the envelopes. Eg: 4 envelopes',
                },
                { count: qtyConfig.quantity },
              )}
            >
              <FlexBox alignItems="center">
                <FormattedMessage defaultMessage="{count}" description="Count of the envelopes. Eg: 4 envelopes" values={{ count: qtyConfig.quantity }} />
                &nbsp;
                {showPrice && (
                  <P>
                    <Pricing style={{ display: 'flex', alignItems: 'center' }}>
                      (
                      <DiscountedPrice
                        classNameWrapper={classes.pricingOnATCButton}
                        newQty={qtyConfig.quantity}
                        totalPrice={qtyConfig.totalDiscountedPrice.toString()}
                      />
                      )
                    </Pricing>
                  </P>
                )}
              </FlexBox>
            </ListboxOption>
          ))}
        </ListboxList>
      </ListboxPopover>
    </Listbox>
  )
}
