import { AddressDisplay } from '@vp/address-display'
import { Box, Button, Card, Typography } from '@vp/swan'
import { useLocalization } from 'hooks/use-localization'
import { ErrorBoundary } from 'lib/errors'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import classes from './suggested-address.module.scss'
const AddressDisplayFallback: FC<{ address: IAddressDetails }> = ({ address }) => (
  <Box>
    <Typography fontSize={'standard'} textColor="standard">
      {address.streetNumber} {address.streetName}
    </Typography>
    <Typography fontSize={'standard'} textColor="standard">
      {address.city} {address.state} {address.postalCode}
    </Typography>
    <Typography fontSize={'standard'} textColor="standard">
      {address.country}
    </Typography>
  </Box>
)

const FullName: FC<{ address: IAddressDetails }> = ({ address }) => (
  <Typography fontSize={'standard'} textColor="standard">
    {address.salutation} {address.firstName} {address.middleName} {address.lastName} {address.suffix} {address.title} {address.company}
  </Typography>
)

const AddressTitle: FC<{ title: string }> = ({ title }) => (
  <Typography fontSize={'standard'} fontWeight="bold" mb={2} className={classes.addressTitle}>
    {title}
  </Typography>
)

export const SuggestedAddress: FC<{ address: IAddressDetails; onAddressSelection: () => void; titleForAddress?: string }> = ({
  address,
  onAddressSelection,
  titleForAddress,
}) => {
  const { locale } = useLocalization()
  return (
    <Card bordered bgc="standard" mb={6} className={classes.addressCard}>
      {titleForAddress && <AddressTitle title={titleForAddress} />}
      <FullName address={address} />
      <ErrorBoundary fallback={<AddressDisplayFallback address={address} />}>
        <AddressDisplay
          locale={locale}
          address={{
            firstName: '',
            lastName: '',
            streetName: address.streetName || '',
            additionalStreetInfo: address.additionalStreetInfo || '',
            postalCode: address.postalCode || '',
            city: address.city || '',
            state: address.state || '',
            country: address.country || '',
            phone: address.phone || '',
            company: '',
            email: '',
            id: address.rowIndex.toString(),
          }}
        />
      </ErrorBoundary>
      <Button type="submit" skin="primary" mt={4} px={5} onClick={onAddressSelection}>
        <FormattedMessage defaultMessage="Use this address" description="Button label to select and save suggested address" />
      </Button>
    </Card>
  )
}
