import { Dropdown, DropdownOption, DropdownWithFloatingLabel, DropdownFloatingLabel } from '@vp/swan'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

interface DropdownFieldProps {
  label: string
  name: string
  optionValues: {
    value: string
    display: string
  }[]
  isRequired: boolean
  readOnly: boolean
}

const DropdownField = (props: DropdownFieldProps) => {
  const { label, name, optionValues, isRequired, readOnly } = props

  const intl = useIntl()

  return (
    <DropdownWithFloatingLabel fullWidth>
      <Field as={Dropdown} name={name} disabled={readOnly || optionValues.length === 1} fullWidth aria-required={isRequired}>
        <DropdownOption value="no-option-selected-placeholder" hidden>
          {intl.formatMessage({ defaultMessage: 'Select' })}
        </DropdownOption>
        {optionValues.map(option => (
          <DropdownOption value={option.value} key={option.value}>
            {option.display}
          </DropdownOption>
        ))}
      </Field>
      <DropdownFloatingLabel>{label}</DropdownFloatingLabel>
    </DropdownWithFloatingLabel>
  )
}

export { DropdownField }
