import { Box, Checkbox, Divider, FlexBox, TabContent, TabHeader, Tabs, TabsContents, TabsHeaders, Typography, useScreenClass } from '@vp/swan'
import classNames from 'classnames'
import { ShimmerTablet } from 'components/loader/shimmer/shimmer.component'
import { useAddressList } from 'lib/address-list'
import { TabIds, useReviewStrategyConfig, useReviewTabId } from 'modules/review/contexts/review-config.context'
import { FC, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import { recipientsCount } from 'utilities/address.utils'
import { DuplicateAddressTab } from '../address-tab/duplicate-tab.component'
import { InvalidAddressTab } from '../address-tab/invalid-tab.component'
import { ValidAddressTab } from '../address-tab/valid-tab.component'
import classes from './address-tabs.module.scss'
import { AddButton } from '../address-tab/add-button.component'
import { ErrorBoundary } from 'lib/errors'
import { AddressListType } from 'modules/review/types/address-multi-selection.types'
import { QUERY_KEY_MAILING_LIST_ID } from 'constants/query-key.constants'
import { getInitialValue } from 'hooks/use-query-string-state.hook'
import { DisplayListName } from '../address-list/display-list-name.component'
import { AddressFormatInfo } from '../address-format-info/address-format-info.component'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import FileNameEditor from 'layouts/page/FileNameEditor'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

type AvailableTab = {
  valid: boolean
  invalid: boolean
  duplicate: boolean
}

export const AddressTabsPlaceholder: FC<unknown> = () => (
  <Box className={classNames(classes.tabsContainer, classes.validColor)} my={6} backgroundColor={'standard'}>
    <Box pt={4}>
      <ShimmerTablet width={25} sizeVariant="large" />
    </Box>
    <Divider mb={5} />
    {[1, 2, 3].map(u => (
      <Box p={4} key={u}>
        <FlexBox>
          <Checkbox ml={5} mr={7} />
          <Box style={{ flex: '1' }}>
            <ShimmerTablet sizeVariant="small" mb={3} />
            <ShimmerTablet sizeVariant="small" mb={3} width={25} />
            <ShimmerTablet sizeVariant="small" mb={3} width={75} />
            <ShimmerTablet sizeVariant="small" width={30} />
          </Box>
        </FlexBox>
        <Divider mt={6} />
      </Box>
    ))}
  </Box>
)

function getActiveTab(validTab: AvailableTab, currentTab: TabIds): TabIds {
  if (currentTab && validTab[currentTab]) return currentTab
  if (validTab.invalid) return 'invalid'
  if (validTab.duplicate) return 'duplicate'
  if (validTab.valid) return 'valid'
  return null
}

export const AddressTabs: FC<{
  isIdle: boolean
  isLoading: boolean
  isPreviewLoading: boolean
}> = ({ isIdle, isLoading, isPreviewLoading }) => {
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const { data: addressListData } = useAddressList()
  const [activeTabId, setActiveTabId] = useReviewTabId()
  const [currentStrategy] = useReviewStrategyConfig()
  const isSavedListFlow = getInitialValue(QUERY_KEY_MAILING_LIST_ID, '')
  // const addRecipientMsg = useMsgAddRecipient()
  const validTabIds = useMemo(
    (): Record<NonNullable<TabIds>, boolean> => ({
      invalid: !!addressListData?.invalidAddresses?.length,
      valid: !!addressListData?.validAddresses?.length,
      duplicate: !!addressListData?.duplicatedAddressGroups?.length,
    }),
    [addressListData],
  )
  const isPostcard = usePostcardPRDCheck()

  const screenClass = useScreenClass()

  const currentActiveTab = getActiveTab(validTabIds, activeTabId)

  useEffect(() => {
    setActiveTabId(currentActiveTab)
  }, [currentActiveTab, setActiveTabId])

  const forSavedListFlow = !!isSavedListFlow && manualAddressingFeatureToggle

  if (isIdle || (isLoading && !addressListData)) return <AddressTabsPlaceholder />

  if (!addressListData) return null

  if (!currentStrategy && (validTabIds.invalid || validTabIds.duplicate)) return null

  if (!currentActiveTab) {
    return (
      <ErrorBoundary>
        <Box bgc="standard">
          <Box pt={4} pb={5} className={classes.noListHeader}>
            {!loadingManualAddressingFlag &&
              (forSavedListFlow ? (
                <FlexBox alignItems="center">
                  <FileNameEditor initialFileName={addressListData?.originalFileName} />
                </FlexBox>
              ) : (
                <DisplayListName />
              ))}
          </Box>
          <FlexBox flexDirection="column" justifyContent="center" py={8}>
            <Typography textAlign="center" textColor="subtle" mb={6} fontSize={'small'}>
              <FormattedMessage defaultMessage="The list is currently empty." description="Text for empty list" />
            </Typography>
            {!loadingManualAddressingFlag && (
              <AddButton disabledCheck={false} forSavedListFlow={forSavedListFlow} justifyContent="center" skin="primary" px={7}>
                <FormattedMessage defaultMessage="Add recipients" description="Label for add recipient button" />
              </AddButton>
            )}
          </FlexBox>
        </Box>
        {!loadingManualAddressingFlag && forSavedListFlow && (
          <Box mt={8}>
            <AddressFormatInfo />
          </Box>
        )}
      </ErrorBoundary>
    )
  }

  return (
    <>
      <Box
        id="address-tabs-box"
        className={classNames(classes.tabsContainer)}
        backgroundColor="standard"
        pt={forSavedListFlow ? undefined : 5}
        mb={forSavedListFlow ? 8 : undefined}
      >
        <Tabs selectedTabId={currentActiveTab} defaultSelectedTabId="invalid" onRequestTabChange={nextActiveTabId => setActiveTabId(nextActiveTabId as TabIds)}>
          <TabsHeaders
            style={
              forSavedListFlow
                ? {
                    boxShadow: 'sem-elevation-standard',
                    borderTopLeftRadius: 'var(--swan-sem-border-radius-standard)',
                    borderTopRightRadius: 'var(--swan-sem-border-radius-standard)',
                  }
                : {}
            }
          >
            {validTabIds.invalid && (
              <TabHeader tabId="invalid" px={{ xs: 5, sm: 5, md: 6, lg: 6 }}>
                {addressListData.invalidAddresses?.length === 1 ? (
                  <FormattedMessage defaultMessage="Error (1)" description="Tab label to show 1 invalid address e.g. Errors(1)" />
                ) : (
                  <FormattedMessage
                    defaultMessage="Errors ({errorsCount})"
                    description="Tab label to show total number of invalid addresses e.g. Errors(10)"
                    values={{ errorsCount: addressListData.invalidAddresses?.length || 0 }}
                  />
                )}
              </TabHeader>
            )}
            {validTabIds.duplicate && (
              <TabHeader tabId="duplicate" px={{ xs: 5, sm: 5, md: 6, lg: 6 }}>
                <FormattedMessage
                  defaultMessage="Duplicates ({duplicateCount})"
                  description="Tab label to show total number of duplicate addresses e.g. Duplicates(6)"
                  values={{
                    duplicateCount:
                      recipientsCount({
                        listType: AddressListType.INPROGRESS,
                        addresses: { duplicatedAddressGroups: addressListData.duplicatedAddressGroups } as UploadedAddressesResponse,
                      }) || 0,
                  }}
                />
              </TabHeader>
            )}
            {validTabIds.valid && (
              <TabHeader tabId="valid" px={{ xs: 5, sm: 5, md: 6, lg: 6 }} mr={0} style={forSavedListFlow ? { width: '100%' } : {}}>
                {!loadingManualAddressingFlag &&
                  (isPostcard ? (
                    <FormattedMessage
                      defaultMessage="Valid ({validCount})"
                      description="Tab label to show total number of valid addresses e.g. Valid(6)"
                      values={{ validCount: addressListData.validAddresses?.length || 0 }}
                    />
                  ) : forSavedListFlow ? (
                    <FlexBox alignItems="center" justifyContent="space-between" pt={3} px={{ xs: 3, sm: 3, md: 5, lg: 5 }}>
                      <FileNameEditor initialFileName={addressListData?.originalFileName} />
                      <ErrorBoundary>
                        <AddButton disabledCheck={false} forSavedListFlow={true} marginLeft="auto" showIcon={true}>
                          {screenClass === 'xs' ? (
                            <FormattedMessage defaultMessage="Add" description="Label for add recipient button" />
                          ) : (
                            <FormattedMessage defaultMessage="Add recipient" description="Label for add recipient button" />
                          )}
                        </AddButton>
                      </ErrorBoundary>
                    </FlexBox>
                  ) : (
                    <FormattedMessage
                      defaultMessage="Okay ({validCount})"
                      description="Tab label to show total number of valid addresses e.g. Valid(6)"
                      values={{ validCount: addressListData.validAddresses?.length || 0 }}
                    />
                  ))}
              </TabHeader>
            )}
          </TabsHeaders>
          <TabsContents p="0">
            {!!addressListData?.invalidAddresses?.length && (
              <TabContent tabId="invalid">
                <InvalidAddressTab addresses={addressListData.invalidAddresses} isPreviewLoading={isPreviewLoading} />
              </TabContent>
            )}
            {!!addressListData?.duplicatedAddressGroups?.length && (
              <TabContent tabId="duplicate">
                <DuplicateAddressTab addresses={addressListData.duplicatedAddressGroups} isPreviewLoading={isPreviewLoading} />
              </TabContent>
            )}
            {!!addressListData?.validAddresses?.length && (
              <TabContent tabId="valid">
                <ValidAddressTab addresses={addressListData.validAddresses} isPreviewLoading={isPreviewLoading} />
              </TabContent>
            )}
          </TabsContents>
        </Tabs>
      </Box>
      {!loadingManualAddressingFlag && manualAddressingFeatureToggle && (
        <Box mb={10}>
          <AddressFormatInfo />
        </Box>
      )}
    </>
  )
}
