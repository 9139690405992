import { ErrorBoundary } from 'lib/errors'
import { ActionType, AddressType } from 'modules/review/types/address-multi-selection.types'
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { noOp } from 'utilities/functions.utils'
import { DeleteAddresses } from '../modal-delete/delete-addresses.component'
import { EditAddresses } from '../modal-edit/edit-addresses.component'
import { SelectAddresses } from '../modal-select/select-addresses.component'

export type TabActionConfig = {
  action: ActionType
  ids?: string[] // making it optional because this isn't required for Add operation
}
export type ActionInput = string | string[] | null | undefined
export type ActionFn = (input: ActionInput) => void
export type ValidationTabActionContextValue = {
  actionConfig: TabActionConfig
  onNone: () => void
  onDelete: ActionFn // used
  onEdit: ActionFn // used
  onSelect: ActionFn
}
const AddressActionsContext = createContext<ValidationTabActionContextValue>({
  actionConfig: { action: ActionType.NONE, ids: [] },
  onNone: noOp,
  onDelete: noOp,
  onEdit: noOp,
  onSelect: noOp,
})

function formatActionInput(input: ActionInput) {
  if (input === null || input === undefined) return []
  if (Array.isArray(input)) return input
  return [input]
}

export const AddressActionsProvider: FC<PropsWithChildren<{ addressType: AddressType }>> = ({ children, addressType }) => {
  const [actionConfig, setActionConfig] = useState<TabActionConfig>({ action: ActionType.NONE, ids: [] })

  const onNone = useCallback(() => {
    setActionConfig({ action: ActionType.NONE, ids: [] })
  }, [])
  const onDelete = useCallback((input: ActionInput) => {
    setActionConfig({ action: ActionType.DELETE, ids: formatActionInput(input) })
  }, [])
  const onEdit = useCallback((input: ActionInput) => {
    setActionConfig({ action: ActionType.EDIT, ids: formatActionInput(input) })
  }, [])

  const showSelectionModal = useCallback((input: ActionInput) => {
    setActionConfig({ action: ActionType.SELECT, ids: formatActionInput(input) })
  }, [])
  const actionValue = useMemo(
    (): ValidationTabActionContextValue => ({
      actionConfig,
      onNone,
      onDelete,
      onEdit,
      onSelect: showSelectionModal,
    }),
    [actionConfig, onNone, onDelete, onEdit, showSelectionModal],
  )
  const emptyString: string[] = []

  return (
    <AddressActionsContext.Provider value={actionValue}>
      {children}

      <ErrorBoundary>
        {actionConfig.action === ActionType.DELETE && !!actionConfig.ids?.length && (
          <DeleteAddresses isOpen={!!actionConfig.ids.length} type={addressType as AddressType} ids={actionConfig.ids} onExit={onNone} />
        )}
        {
          <EditAddresses
            isOpen={actionConfig.action === ActionType.EDIT && !!actionConfig.ids?.length}
            type={addressType as AddressType}
            ids={actionConfig.ids ? actionConfig.ids : emptyString}
            onExit={onNone}
          />
        }
        {actionConfig.action === ActionType.SELECT && !!actionConfig.ids?.length && (
          <SelectAddresses isOpen={!!actionConfig.ids.length} groupId={actionConfig.ids[0]} onExit={onNone} />
        )}
      </ErrorBoundary>
    </AddressActionsContext.Provider>
  )
}

export function useAddressActions() {
  return useContext(AddressActionsContext)
}
