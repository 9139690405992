export enum SelectionStrategy {
  NONE = 'NONE',
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export type IAddressDetails = {
  structuredAddress?: object
  id?: string
  displayName?: string
  additionalStreetInfo?: string
  addressLine1?: string
  addressLine2?: string
  city: string
  company?: string
  companyName?: string
  country: string
  firstName?: string
  lastName: string
  middleName?: string
  phone?: string
  poBox?: string
  postalCode: string
  region?: string
  rowIndex: number
  salutation?: string
  state: string
  streetName: string
  streetNumber?: string
  suffix?: string
  title?: string
  zipCode?: number
  showExcess?: boolean
}
export type IAddress = {
  addressId: string
  displayName: string
  personId: string
  structuredAddress: IAddressDetails
}

export type IAddressComplete = {
  id?: string
  displayName?: string
  structuredAddress: IAddressDetails | undefined
}
export type errorCode = 'invalidCharacters' | 'invalidFormat' | 'invalidValue' | 'missingField' | 'tooLong' | 'tooShort'

export type AddressErrorDetails = Record<string, Record<string, errorCode>>

export type DuplicatedAddressGroup = {
  addresses: IAddressDetails[]
  isValid: boolean
  samePerson: boolean
  suggestedAddress?: IAddressDetails
}

export type AddressWithSuggestion = {
  originalAddress: IAddressDetails
  suggestedAddress?: IAddressDetails
  errors?: AddressErrorDetails
}

export interface MailingList {
  mailingListId: string
  shopperId: string
  name: string
  createdOn: number
  lastUpdatedOn: number
  addresses: Array<IAddress>
}
