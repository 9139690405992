import { Button, Checkbox, Divider, FlexBox, Icon, Spinner, Typography, useScreenClass } from '@vp/swan'
import classNames from 'classnames'
import { useDeletionInProgress } from 'lib/intl/msg-address-dialog.hooks'
import { useMsgAlternateTextDeleteLogo } from 'lib/intl/msg-alternate-texts.hooks'
import { useAddressTabHeaderMessage } from 'modules/review/components/address-tab/address-tab-header.hook'
import { useOnDeleteHook } from 'modules/review/components/modal-delete/on-delete.hook'
import { AddressType, MultiSelectionStatus } from 'modules/review/types/address-multi-selection.types'
import { FC, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { AddButton } from './add-button.component'
import { useAddressSelection } from './address-selection.context'
import headerClasses from './address-tab-header.module.scss'
import classes from './address-tab-save-flow.module.scss'
import { QUERY_KEY_MAILING_LIST_ID } from 'constants/query-key.constants'
import { getInitialValue } from 'hooks/use-query-string-state.hook'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

const SelectionCheckbox: FC<{ message: string }> = ({ message }) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const { toggleStatus, status } = useAddressSelection()

  useEffect(() => {
    if (!checkboxRef.current) return undefined
    if (status === MultiSelectionStatus.SOME) {
      checkboxRef.current.indeterminate = true
      checkboxRef.current.checked = false
    } else {
      checkboxRef.current.indeterminate = false
      checkboxRef.current.checked = status === MultiSelectionStatus.ALL
    }
  }, [status])

  return (
    <>
      <label htmlFor={message}></label>
      <Checkbox id={message} ref={checkboxRef} onClick={toggleStatus} mr={{ xs: 5, sm: 5, md: 7, lg: 7 }} my={3} title={message} />
    </>
  )
}

export const AddressTabHeaderSaveFlow: FC<{ addressType: AddressType }> = ({ addressType }) => {
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const isSavedListFlow = getInitialValue(QUERY_KEY_MAILING_LIST_ID, '')
  const deleteAddressMessage = useDeletionInProgress()
  const { selectedKeys } = useAddressSelection()
  const { onDelete, isLoading, isEnabled } = useOnDeleteHook(selectedKeys, addressType)
  const messages = useAddressTabHeaderMessage(addressType, selectedKeys.length)
  const alternateTextDeleteLogo = useMsgAlternateTextDeleteLogo()
  const disabledCheck = !(!selectedKeys.length || (!isEnabled && isLoading))
  const listHeaderRef = useRef<HTMLElement>(null)
  const screenSize = useScreenClass()

  return (
    <>
      <FlexBox
        justifyContent="space-between"
        flexDirection={screenSize === 'xs' ? 'column' : 'row'}
        paddingY={4}
        px={{ xs: 5, sm: 5, md: 6, lg: 6 }}
        className={classes.headerShadow}
        ref={listHeaderRef}
      >
        <FlexBox alignItems="center">
          <SelectionCheckbox message={selectedKeys.length ? messages.checkboxTitleUnSelectAll : messages.checkboxTitleSelectAll} />
          <Typography fontSize={'standard'} as="span">
            {selectedKeys.length ? (
              messages.selectedAddressesSavedFlow
            ) : (
              <FormattedMessage defaultMessage="Select all" description="Label for selecting all addresses" />
            )}
          </Typography>
        </FlexBox>

        {!loadingManualAddressingFlag && !(isSavedListFlow && manualAddressingFeatureToggle) && (
          <AddButton disabledCheck={disabledCheck} px={3} marginLeft="auto" showIcon={true}>
            <FormattedMessage defaultMessage="Add" description="Label for add button" />
          </AddButton>
        )}

        {selectedKeys.length ? (
          <div className={screenSize === 'xs' ? classes.deleteStyle : classes.deleteStyleDesktop}>
            <Button
              buttonShape="round"
              disabled={!selectedKeys.length || !isEnabled || isLoading}
              onClick={onDelete}
              title={messages.deleteActionButtonTitle}
              px={3}
              fontWeight="bold"
            >
              <Icon
                iconType="delete"
                size="20p"
                skin="standard"
                alt={alternateTextDeleteLogo}
                className={classNames([isLoading ? headerClasses.fadedLogo : ''])}
              />
              {isLoading && <Spinner className={headerClasses.deletePreloader} accessibleText={deleteAddressMessage} />}
            </Button>
          </div>
        ) : null}
      </FlexBox>
      <Divider mx={{ xs: 5, sm: 5, md: 6, lg: 6 }} my={0} />
    </>
  )
}
