import { Button } from '@vp/swan'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { useEventTracking } from 'lib/telemetry'
import { AdditionalTrackingDataType, PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
export const DoneOrNextBtn: FC<{
  hasNext: boolean
  onClick: () => void
  onExit: () => void
  disabled: boolean
}> = ({ hasNext, onClick, onExit, disabled }) => {
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)
  const fireTracking = useEventTracking()
  const handleDone = () => {
    const flyOutTrackingData: AdditionalTrackingDataType = {
      category: TrackingCategoryType.FLY_OUT,
      label: TrackingLabelType.FLY_OUT_SELECTION,
      pageName: trackingProductPageName,
      eventDetail: 'Done',
    }
    fireTracking(TrackingEventType.FLY_OUT_CLICKED, flyOutTrackingData)
    onExit()
  }
  return (
    <>
      <Button type="submit" skin="primary" width="full-width" mt={4} disabled={disabled} onClick={onClick}>
        {hasNext ? (
          <FormattedMessage
            defaultMessage="Save and Continue"
            description="Button label to add a recipient and continue further to add other recipient in the list"
          />
        ) : (
          <FormattedMessage defaultMessage="Save" description="Button label to save last recipient" />
        )}
      </Button>
      <Button id="done-button" type="submit" width="full-width" mt={4} onClick={handleDone}>
        <FormattedMessage defaultMessage="Done" description="Button label to close add recipient fly-out" />
      </Button>
    </>
  )
}
