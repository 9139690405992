import { ErrorBoundary } from 'lib/errors'
import { ActionType } from 'modules/review/types/address-multi-selection.types'
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { noOp } from 'utilities/functions.utils'
import { AddAddress } from '../modal-add/add-address.component'
import { UpdateQuantity } from '../modal-quantity/update-quantity-more-recipients.component'
import { useProductInfoQtySelection } from 'lib/product-info'
import { useAddressList } from 'lib/address-list'
import { useUpdateWorkDetails, useWorkDetails } from 'lib/work-entity'
import { useProductInfoUpdate } from 'lib/product-info/product-info.context'

export type TabActionConfig = {
  action: ActionType
  savedListFlow?: boolean
  shouldShowAddMoreEnvelopesModal?: boolean
}
export type ActionInput = string | string[] | null | undefined
export type ActionFn = (input: ActionInput) => void
export type AddActionFn = (savedListFlow?: boolean, shouldShowAddMoreEnvelopesModal?: boolean) => void
export type ValidationTabActionContextValue = {
  actionConfig: TabActionConfig
  onNone: () => void
  onAdd: AddActionFn
}
const AddAddressActionContext = createContext<ValidationTabActionContextValue>({
  actionConfig: { action: ActionType.NONE },
  onNone: noOp,
  onAdd: noOp,
})

export const AddAddressActionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [actionConfig, setActionConfig] = useState<TabActionConfig>({ action: ActionType.NONE })
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [newQty, setnewQty] = useState('')

  const { data: workDetails } = useWorkDetails()
  const {
    mutationResult: { mutateAsync: updateQuantityInWork },
  } = useUpdateWorkDetails()

  const onNone = useCallback(() => {
    setActionConfig({ action: ActionType.NONE })
  }, [])

  const onAdd = useCallback((savedListFlow?: boolean, shouldShowAddMoreEnvelopesModal?: boolean) => {
    setActionConfig({ action: ActionType.ADD, savedListFlow, shouldShowAddMoreEnvelopesModal })
  }, [])

  const actionValue = useMemo(
    (): ValidationTabActionContextValue => ({
      actionConfig,
      onNone,
      onAdd,
    }),
    [actionConfig, onNone, onAdd],
  )

  const { selectedQty: selectedEnvelopes } = useProductInfoQtySelection()
  const { data: addresses } = useAddressList()

  const shouldShowAddAddressesModal = actionConfig.action === ActionType.ADD && !actionConfig.shouldShowAddMoreEnvelopesModal

  const toggleQuantityModal = () => {
    setActionConfig({ action: ActionType.NONE, shouldShowAddMoreEnvelopesModal: false })
    setIsAddingToCart(false)
  }

  const updateSelection = useProductInfoUpdate()

  // handles the continue cta click
  const onQuantityChange = () => {
    setIsAddingToCart(true)
    toggleQuantityModal()
  }

  // handles the quantity dropdown change
  const onChange = (newQty: string) => {
    setnewQty(newQty)
    // update work details
    if (workDetails?.merchandising) {
      workDetails.merchandising.quantity = Number(newQty)
      updateQuantityInWork(workDetails)
    }
    // update react context
    updateSelection({
      qty: Number(newQty),
    })
  }

  return (
    <AddAddressActionContext.Provider value={actionValue}>
      {children}

      <ErrorBoundary>
        <AddAddress isOpen={shouldShowAddAddressesModal} savedListFlow={actionConfig.savedListFlow} onExit={onNone} />
        <UpdateQuantity
          isOpen={actionConfig.shouldShowAddMoreEnvelopesModal as boolean}
          onClose={toggleQuantityModal}
          onQuantityChange={onQuantityChange}
          addressesInList={addresses?.validAddresses?.length || 0}
          selectedEnvelopesQty={selectedEnvelopes}
          disableATCBtn={isAddingToCart}
          onChange={onChange}
          newQty={newQty}
        />
      </ErrorBoundary>
    </AddAddressActionContext.Provider>
  )
}

export function useAddAddressActions() {
  return useContext(AddAddressActionContext)
}
