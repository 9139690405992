/* eslint-disable react/display-name */
import {
  Column,
  FormError,
  FormField,
  FormInputGroup,
  FormLabel,
  FormLabelOptional,
  GridContainer,
  Row,
  StandardForm,
  TextInput,
  TextInputFloatingLabel,
  TextInputWithFloatingLabel,
} from '@vp/swan'
import { ErrorMessage, Field, FormikContextType, FormikValues, useFormikContext } from 'formik'
import { Notifier } from 'utilities/Notifier'
import { FC, PropsWithChildren, useCallback, useEffect } from 'react'
import { AddressFormVariant, FORM_TYPES, FieldVariantTypes } from './address-form.constants'
import { useFieldConfig } from './field-config.hook'
import { validatorFactory } from './validator'
import { useLocalization } from 'hooks/use-localization'
import { DropdownField } from './dropdown-field'
import { getTopDomain } from 'lib/site-links/site-link.utils'
import { localizeStates } from 'utilities/functions.utils'
import { US_STATE_CODES, CA_PROVINCE_CODES, STATE_LOCALIZATION_MAP } from 'constants/locale.constants'
import { useIntl } from 'react-intl'
import { useMsgEditRecipientsValidation } from 'lib/intl/msg-edit-action.hooks'
import { AdditionalTrackingDataType, PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useEventTracking } from 'lib/telemetry'
import { useTrackingProductPageName } from 'hooks/use-product.hook'

export const InputFieldComponent = (props: any & { variant?: FieldVariantTypes }) => {
  const { name, label, variant, value = '', ...otherProps } = props

  const { locale } = useLocalization()
  const intl = useIntl()

  if (name === 'state') {
    const validStateCodes = {
      US: US_STATE_CODES,
      CA: CA_PROVINCE_CODES,
    }
    const countryValue = `${getTopDomain(locale)}`?.toUpperCase()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const dropdownOptions = validStateCodes[countryValue] || []

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const localizedStateDisplayNames = localizeStates(countryValue, dropdownOptions, STATE_LOCALIZATION_MAP[locale.toUpperCase()])

    return (
      <DropdownField
        label={intl.formatMessage({ defaultMessage: 'State' })}
        name={name}
        optionValues={localizedStateDisplayNames}
        isRequired={true}
        readOnly={false}
      />
    )
  } else if (variant === 'floating') {
    return (
      <TextInputWithFloatingLabel data-testid="floating-variant">
        <TextInput value={value} {...otherProps} name={name} />
        <TextInputFloatingLabel>{label}</TextInputFloatingLabel>
      </TextInputWithFloatingLabel>
    )
  }
  return <TextInput value={value} {...otherProps} name={name} />
}
interface AddressFormCustomInputsProps {
  formType: FORM_TYPES
  onValueChanged: (newValues: FormikValues, isValid: boolean) => void
  notifier: Notifier
}
export const AddressFormCustomInputs: FC<PropsWithChildren<AddressFormCustomInputsProps>> = ({ children, formType, onValueChanged, notifier }) => {
  const intl = useIntl()

  const addressMessages = useMsgEditRecipientsValidation()
  const fireTracking = useEventTracking()
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)

  const { values, setFieldValue, handleBlur, errors, setTouched } = useFormikContext() as FormikContextType<FormikValues>
  const configs = useFieldConfig(formType)
  const { locale } = useLocalization()
  useEffect(() => {
    const isValid = typeof errors === 'undefined' || Object.keys(errors).length === 0
    onValueChanged(values, isValid)
  }, [values, errors, onValueChanged])

  const validateForm = useCallback(() => {
    setTouched(
      {
        firstName: true,
        middleName: true,
        lastName: true,
        company: true,
        suffix: true,
        title: true,
        postalCode: true,
        streetName: true,
        additionalStreetInfo: true,
        city: true,
        state: true,
      },
      true,
    )
  }, [setTouched])

  useEffect(() => {
    notifier.subscribe(validateForm)

    return () => {
      notifier.unsubscribe(validateForm)
    }
  }, [notifier, validateForm])

  return (
    <StandardForm skin="tight" as="div">
      <GridContainer>
        {configs.map(config => (
          <Row key={config.key}>
            <Column span={12}>
              <FormField>
                {AddressFormVariant === 'default' && (
                  <FormLabel>
                    {config.label}
                    {config.isOptional && config.key !== 'company' ? (
                      <FormLabelOptional>{intl.formatMessage({ defaultMessage: 'Optional' })}</FormLabelOptional>
                    ) : null}
                  </FormLabel>
                )}
                <FormInputGroup>
                  <Field
                    name={config.key}
                    label={config.label}
                    variant={AddressFormVariant}
                    validate={validatorFactory(config, locale)}
                    as={InputFieldComponent}
                    onBlur={(e: any) => {
                      const trimmedValue = e.target.value.trim()
                      const flyOutTrackingData: AdditionalTrackingDataType = {
                        category: TrackingCategoryType.FLY_OUT,
                        label: TrackingLabelType.FLY_OUT_SELECTION,
                        pageName: trackingProductPageName,
                        eventDetail: 'add_textInput_' + config.label,
                      }
                      fireTracking(TrackingEventType.FLY_OUT_CLICKED, flyOutTrackingData)
                      setFieldValue(config.key, trimmedValue, true)
                      handleBlur(e)
                    }}
                  />
                  <ErrorMessage name={config.key}>
                    {(errorCode: any) => <>{errorCode.trim().length ? <FormError>{addressMessages(config.label)[errorCode]}</FormError> : null}</>}
                  </ErrorMessage>
                </FormInputGroup>
              </FormField>
            </Column>
          </Row>
        ))}
      </GridContainer>
      {children}
    </StandardForm>
  )
}
