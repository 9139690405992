import {
  FlexBox,
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Typography,
  ModalDialogFooter,
  Icon,
} from '@vp/swan'
import { QuantitySelector } from 'components/quantity/quantity-selector.component'
import { DiscountedPrice } from 'components/price/discounted-price.component'
import { withErrorBoundary } from 'lib/errors'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNeedMoreEnvelopesMsg, useNeedMoreEnvelopesTitle, useNeedMoreEnvelopesModalContent } from 'lib/intl/msg-validation-header.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import classes from './update-quantity.module.scss'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { useEventTracking } from 'lib/telemetry/tracking.hooks'
import { AdditionalTrackingDataType, PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'

type UpdateQuantityParams = {
  isOpen: boolean
  onAddToCart: () => void
  onClose: () => void
  addressesInList: number
  selectedEnvelopesQty: number
  disableATCBtn?: boolean
}

const UpdateQuantityInternal: FC<UpdateQuantityParams> = ({ onAddToCart, onClose, isOpen, addressesInList, selectedEnvelopesQty, disableATCBtn = false }) => {
  const needMoreEnvelopesMsg = useNeedMoreEnvelopesMsg(addressesInList, selectedEnvelopesQty)
  const needMoreEnvelopesTitle = useNeedMoreEnvelopesTitle(addressesInList, selectedEnvelopesQty)
  const modalCloseLabel = useMsgModalClose()
  const needMoreEnvelopesContent = useNeedMoreEnvelopesModalContent()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const envelopeQtyStatus = () => {
    if (addressesInList > selectedEnvelopesQty) {
      return <Icon iconType="error" size="16p" skin="error" className={classes.envelopesMsgImg} />
    } else {
      return <Icon iconType="success" size="16p" skin="success" className={classes.envelopesMsgImg} />
    }
  }

  const fireTracking = useEventTracking()
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)

  useEffect(() => {
    if (!isOpen) return

    const flyOutTrackingData: AdditionalTrackingDataType = {
      category: TrackingCategoryType.FLY_OUT,
      label: TrackingLabelType.UPDATE_QUANTITY_FLY_OUT,
      pageName: trackingProductPageName,
    }
    fireTracking(TrackingEventType.EXPERIMENT_EVENT_NAME, flyOutTrackingData)
  }, [isOpen, fireTracking, trackingProductPageName])

  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={onClose}>
      <ModalDialogContent p={7} aria-labelledby={needMoreEnvelopesContent}>
        <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
        <ModalDialogHeader mb={5}>
          <ModalDialogTitle>{needMoreEnvelopesTitle}</ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          <QuantitySelector isInModal fullWidth />
          <FlexBox py={5}>
            {envelopeQtyStatus()}
            <Typography fontSize={'standard'} textColor="subtle" pl={2} style={{ textWrap: 'wrap' }} textAlign="left">
              {needMoreEnvelopesMsg}
            </Typography>
          </FlexBox>
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons mt={0}>
            <FlexBox flexDirection={'column'} alignItems={'center'} style={{ width: '100%' }}>
              <Button skin="primary" width="full-width" onClick={onAddToCart} disabled={disableATCBtn} className={classes.goBackButton}>
                {!loadingManualAddressingFlag &&
                  (manualAddressingFeatureToggle ? (
                    <FormattedMessage defaultMessage="Update cart" description="Button label for updating cart" />
                  ) : (
                    <FormattedMessage defaultMessage="Add to Cart" description="Button label for adding to cart" />
                  ))}
                <DiscountedPrice classNameWrapper={classes.pricingOnATCButton} />
              </Button>
              <Button skin="unstyled" width="full-width" onClick={onClose} pt={4} fontSize="small" fontWeight="bold" className={classes.goBackButton}>
                <FormattedMessage defaultMessage="Go back to editing" description="Button label to go back to editing" />
              </Button>
            </FlexBox>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export const UpdateQuantity = withErrorBoundary(UpdateQuantityInternal)
