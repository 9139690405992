import { RawPrice } from '@vp/vp-tokenized-fragment'
import { useLocalization } from 'hooks/use-localization'
import { useProductInfoPricingSummary, useProductInfoQtySelection } from 'lib/product-info/product-info.context'
import { FC } from 'react'

type PriceProps = {
  classNameWrapper?: any
  showActualPriceToo?: boolean
  placeHolderText?: string
  newQty?: string
  totalPrice?: string
}

export const DiscountedPrice: FC<PriceProps> = ({ classNameWrapper = {}, showActualPriceToo = false, placeHolderText = '', newQty = '', totalPrice = '' }) => {
  const { locale } = useLocalization()
  const { price, prices } = useProductInfoPricingSummary()
  const { selectedQty } = useProductInfoQtySelection()
  const priceWithWorkQty = prices?.find(qtyFromArray => qtyFromArray.quantity === (newQty || selectedQty).toString())
  // RawPrice renders the discounted price along with actual price with strike through text
  // only if the prop listPrice != discountPrice else show only discountedPrice
  const listPrice = showActualPriceToo ? priceWithWorkQty?.totalListPrice : priceWithWorkQty?.totalDiscountedPrice
  return (
    <RawPrice
      pricingInfo={{
        culture: locale,
        currency: price?.currency || 'USD',
        minimumFractionDigits: price?.fractionDigits || 2,
        vatInc: false,
        hideVatMessage: false,
        listPrice: listPrice || 0,
        discountPrice: priceWithWorkQty?.totalDiscountedPrice || 0,
      }}
      placeHolderText={totalPrice || placeHolderText}
      classNameWrapper={classNameWrapper}
    />
  )
}
