import { FluidImage, SelectionSet, SelectionSetInput, SelectionSetLabel } from '@vp/swan'
import { PreviewArrowButtons } from 'components/address-form/preview-slider/preview-slide.component'

import { SectionLoaderWrapper } from 'components/loader/loader.component'
import { PurcsSlide } from 'components/work-id-preview/purcs-api'
import { PurcsConfig, usePurcsSlides } from 'components/work-id-preview/purcs.hooks'
import classes from 'components/work-id-preview/work-id-preview.module.scss'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import {
  useMsgAlternateTextEnvelopeBackPreview,
  useMsgAlternateTextEnvelopeFrontPreview,
  useMsgAlternateTextEnvelopePreview,
} from 'lib/intl/msg-alternate-texts.hooks'
import { useEventTracking } from 'lib/telemetry/tracking.hooks'
import { PageName, TrackingCategoryType, TrackingEventType } from 'lib/telemetry/tracking.types'
import { stringReplace } from 'modules/review/utilities/address.utils'
import { FC, useMemo, useState } from 'react'

export enum WorkPreviewType {
  STATIC_IMAGE = 'STATIC_IMAGE',
  VORTEX = 'VORTEX',
  DEFAULT = 'DEFAULT',
}

export const WorkIdPreview: FC<
  PurcsConfig & {
    sidesToShow?: (string | undefined)[]
    isPreviewLoading?: boolean
    onPreviewLoad?: () => void
  }
> = ({ workDetails, purpose, sidesToShow, isPreviewLoading, onPreviewLoad }) => {
  const [visibleSide, setVisibleSide] = useState('front')
  const { data: views, isLoading, isIdle } = usePurcsSlides({ workDetails, purpose })

  const alternateTextEnvelopePreview = useMsgAlternateTextEnvelopePreview()
  const alternateTextEnvelopePreviewBack = useMsgAlternateTextEnvelopeBackPreview()
  const alternateTextEnvelopePreviewFront = useMsgAlternateTextEnvelopeFrontPreview()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const fireTracking = useEventTracking()
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)

  const visibleSlides: PurcsSlide[] | null = useMemo(() => {
    if (!views) return null
    if (!sidesToShow || sidesToShow.length === 0) return views
    const filtered = views.filter(view => sidesToShow.includes(view.name?.toLowerCase()) || sidesToShow.includes(view.title.toLowerCase()))
    if (filtered.length) return filtered
    return views
  }, [views, sidesToShow])

  const onLoad = (slide?: string) => {
    if (visibleSide === slide && onPreviewLoad) {
      onPreviewLoad()
    }
  }
  const revisionTrimRegex = /%252Frevisions%[^&]*/g

  const TRACKING_LABEL = 'Preview viewed'
  const fireTrackingPreviewButtons = (navigationDetail: string | null) => {
    if (!navigationDetail) return
    const postageTrackingData = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: TRACKING_LABEL,
      pageName: trackingProductPageName,
      navigationDetail: navigationDetail,
    }
    fireTracking(TrackingEventType.EXPERIMENT_EVENT_NAME, postageTrackingData)
  }

  return (
    <SectionLoaderWrapper showLoader={isLoading || isIdle || isPreviewLoading} className={classes.containerMinHeight}>
      {visibleSlides && visibleSlides.length === 1 ? (
        <FluidImage
          key={visibleSlides[0].name}
          className={classes.placeholder}
          src={`${stringReplace(visibleSlides[0].imageUrl, revisionTrimRegex)}&format=png`}
          alt={alternateTextEnvelopePreview}
        />
      ) : null}
      {visibleSlides && visibleSlides.length > 1 && !loadingManualAddressingFlag ? (
        <>
          <div className={classes.imageWrapper}>
            <PreviewArrowButtons />
            {visibleSlides.map(slide => (
              <FluidImage
                key={slide.name}
                src={`${stringReplace(slide.imageUrl, revisionTrimRegex)}&format=png`}
                className={classes.preview}
                alt={slide?.name?.toLowerCase() === 'front' ? alternateTextEnvelopePreviewFront : alternateTextEnvelopePreviewBack}
                visuallyHidden={!(visibleSide.toLowerCase() === slide.name?.toLowerCase() || visibleSide.toLowerCase() === slide.title.toLowerCase())}
                onLoad={() => onLoad(slide?.name?.toLowerCase())}
              />
            ))}
          </div>
          <SelectionSet
            variant="single-select"
            skin="buttons"
            selectedValue={visibleSide}
            onSelectedValueChange={v => {
              fireTrackingPreviewButtons(v)
              setVisibleSide((v = v || ' '))
            }}
            className={manualAddressingFeatureToggle ? classes.featureFlagPreviewButton : classes.selection}
            mt={{ lg: 4, md: 6 }}
          >
            {visibleSlides.map(slide => (
              <SelectionSetInput key={slide.name} value={slide.name || slide.title}>
                <SelectionSetLabel className={classes.btnColor}>{slide.title || slide.name}</SelectionSetLabel>
              </SelectionSetInput>
            ))}
          </SelectionSet>
        </>
      ) : null}
    </SectionLoaderWrapper>
  )
}
