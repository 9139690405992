import axios from 'axios'
import { AME_REQUESTER } from 'constants/env.constants'
import { getAuthHeader } from 'utilities/api.utility'
import { v4 as uuidv4 } from 'uuid'

// TODO: Hardcding oif base url to be removed
const purcsClient = axios.create({
  baseURL: 'https://lookup.previews.cimpress.io/api/v1/vistaprint-prod',
})

type purcsReq = {
  productKey: string
  productVersion: number | string
  previewInstructionsUri: string
  locale: string
  purpose?: string
  optionSelections: Record<string, string>
  width: number
  token: string
  signal?: AbortSignal
}

export type PurcsSlide = {
  name?: string
  title: string
  imageUrl: string
  sceneType: string
}

export async function getPurcsViews({
  locale,
  productKey,
  productVersion,
  purpose = 'design.studio.review',
  optionSelections,
  width,
  previewInstructionsUri,
  token,
  signal,
}: purcsReq): Promise<PurcsSlide[]> {
  const params = new URLSearchParams({
    productVersion: String(productVersion),
    purpose,
    optionSelections: JSON.stringify(optionSelections),
    requester: AME_REQUESTER,
    width: String(width),
    previewInstructionsUri,
  })
  const res = await purcsClient.get<{
    views: {
      name?: string
      sceneType: string
      _links: {
        image: {
          href: string
          title: string
        }
      }
    }[]
  }>(`/${locale}/product/${productKey}/allViews?${params.toString()}&x-requestId=${uuidv4()}`, {
    signal,
    headers: {
      ...getAuthHeader(token),
    },
  })
  const slides: PurcsSlide[] = []
  if (Array.isArray(res.data?.views)) {
    res.data.views.forEach(view => {
      slides.push({
        name: view.name,
        title: view._links.image.title,
        imageUrl: view._links.image.href + `&x-requestId=${uuidv4()}`,
        sceneType: view.sceneType,
      })
    })
  }
  return slides
}
