import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useLocalization } from 'hooks/use-localization'
import { useMpvId } from 'lib/msx'
import { useQuery } from 'react-query'
import { failedApiPromiseForIncompleteArguments } from 'utilities/api.utility'
import { getSiteFlowNextStep } from './site-flow.api'
import { useLogger } from '@vp/shared-capabilities-component-library/components'

export function useSiteFlowNextSteps(currentExperienceType?: string | null, predictFlow = false, options?: { workId?: string }) {
  const { locale } = useLocalization()
  const workIdQParam = useWorkIdQueryParam()
  const workId = options ? options.workId : workIdQParam
  const mpvId = useMpvId()
  const { logError } = useLogger()
  return useQuery(
    [],
    ({ signal }) => {
      if (workId && mpvId && locale && currentExperienceType) {
        return getSiteFlowNextStep({
          locale,
          mpvId,
          workId,
          currentExperienceType,
          signal,
          predictFlow,
        })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!workId && !!mpvId && !!locale && !!currentExperienceType,
      onError: error => logError('Error in siteflow API', error),
    },
  )
}
