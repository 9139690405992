import { FlexBox, AlertBox, AlertBoxDismissButton, useScreenClass } from '@vp/swan'
import { FormattedMessage } from 'react-intl'
import classes from './title-alert-box.module.scss'

export const TitleAlertBox = () => {
  const screenClass = useScreenClass()
  const customAlert = screenClass === 'xs' ? classes.customAlertMobile : classes.customAlertDesktop
  return (
    <FlexBox pb={{ xs: 7, sm: 5, md: 4 }}>
      <AlertBox className={customAlert}>
        <FormattedMessage
          defaultMessage='Start editing. We’ll duplicate the list and save the original in "My Account" so you don’t lose it.'
          description="Alert for re-entry flow"
        />
        <AlertBoxDismissButton accessibleText="Dismiss alert" />
      </AlertBox>
    </FlexBox>
  )
}
