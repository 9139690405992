import { useAddressList, useAddressListUpdate } from 'lib/address-list'
import { useMsgAddressCurrent, useMsgAddressResidents } from 'lib/intl/msg-address-dialog.hooks'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useToastSomethingWentWrong } from 'lib/toast'
import { SelectionStrategy } from 'modules/review/types/address-list.types'
import { getAddressUpdateRequestForAutoFix } from 'modules/review/utilities/update-request.utils'
import { UseMutateFunction } from 'react-query'
import { UpdateAddressRequest, UploadedAddressesResponse } from 'types/upload-mailing-list.types'

type UpdateFunction = UseMutateFunction<UploadedAddressesResponse, unknown, { request: UpdateAddressRequest }, unknown>

function autoFixRecursive(
  mutate: UpdateFunction,
  currentResidents: { firstName: string; lastName: string },
  addresses: UploadedAddressesResponse,
  onError: () => void,
  retryCount: number,
  logError: (message: string, context: any) => void,
) {
  const addressUpdateRequest = getAddressUpdateRequestForAutoFix(currentResidents, addresses)
  if (!addressUpdateRequest) {
    return null
  } else if (addressUpdateRequest && retryCount <= 0) {
    onError()
    logError('Maximum retry count exhausted while auto fixing issues', {})
    return null
  }
  mutate(
    {
      request: addressUpdateRequest,
    },
    {
      onSuccess: newAddresses => {
        autoFixRecursive(mutate, currentResidents, newAddresses, onError, retryCount - 1, logError)
        newRelicAddPageAction('Auto fix mailing list', { result: 'success' })
      },
      onError: () => {
        onError()
        newRelicAddPageAction('Auto fix mailing list', { result: 'error' })
        logError('Error occurred while auto fixing addresses', { contextData: {} })
      },
    },
  )
}

export function useAutoFix() {
  const { data: addresses } = useAddressList()
  const {
    isEnabled: isUpdateEnabled,
    mutationResult: { mutate: updateAddressList, isLoading: isAutoFixInProgress },
  } = useAddressListUpdate(false, true)
  const unknownErrorToast = useToastSomethingWentWrong()
  const addressCurrent = useMsgAddressCurrent()
  const addressResidents = useMsgAddressResidents()
  const { logError } = useLogger()

  const retryCount = 4
  const fix = (nextStrategy: string, resetAddresses: UploadedAddressesResponse | undefined = addresses) => {
    if (resetAddresses && isUpdateEnabled && nextStrategy === SelectionStrategy.AUTO) {
      const currentResidents = {
        firstName: addressCurrent,
        lastName: addressResidents,
      }
      autoFixRecursive(updateAddressList, currentResidents, resetAddresses, unknownErrorToast, retryCount, logError)
    }
  }

  return { isAutoFixInProgress, fix }
}
