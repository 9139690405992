import { Box, Button, FlexBox, Spinner, Typography } from '@vp/swan'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useAddressListUpdate } from 'lib/address-list'
import { ErrorBoundary } from 'lib/errors'
import { useMsgModalMergeRecipients } from 'lib/intl/msg-modal-select-merge.hooks'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useToastSomethingWentWrong, useToastStandard } from 'lib/toast'
import { DuplicatedAddressGroup } from 'modules/review/types/address-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
import { getIdForAddressDetail, getIdForDuplicateGroup, getIdsForDuplicates } from 'modules/review/utilities/address.utils'
import { getAddressRequestForMerge } from 'modules/review/utilities/update-request.utils'
import { FC, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AddressRow } from '../address-row/address-row.component'
import { AddressActionsProvider, useAddressActions } from './address-actions.context'
import { AddressSelectionProvider, useAddressSelection } from './address-selection.context'
import classes from './duplicate-tab.module.scss'
const SamePersonHeading: FC<{ count: number; addressGroup: DuplicatedAddressGroup }> = ({ count, addressGroup }) => {
  const {
    isEnabled: isUpdateEnabled,
    mutationResult: { mutateAsync: update, isLoading: mergeInProgress },
  } = useAddressListUpdate()
  const errorToast = useToastSomethingWentWrong()
  const standardToastMessage = useMsgModalMergeRecipients(addressGroup?.addresses.length || 0)
  const toast = useToastStandard()
  const workId = useWorkIdQueryParam()
  const intl = useIntl()
  const { logError, logWarn } = useLogger()

  const onMerge = () => {
    const request = getAddressRequestForMerge(addressGroup)
    if (request) {
      update({ request })
        .then(() => {
          toast(standardToastMessage)
          newRelicAddPageAction('Merging duplicate records', { result: 'success' })
        })
        .catch(error => {
          errorToast()
          newRelicAddPageAction('Merging duplicate records', { result: 'error' })

          logError('merging duplicate records failed', { contextData: { request, errorDetails: error, workId } })
        })
    } else {
      errorToast()
      logWarn('request id is not available', { contextData: { request, workId } })
    }
  }
  return (
    <FlexBox justifyContent="space-between" alignItems="center" style={{ width: '100%' }} px={{ xs: 5, sm: 5, md: 6, lg: 6 }} pt={5}>
      <Typography fontSize={'small'} textColor="standard" fontWeight="bold" px={3} py={1} className={classes.badge}>
        <FormattedMessage
          defaultMessage="Addresses with the same recipient"
          description="Label to show addresses with same recipient"
          values={{
            addressLength: count,
          }}
        />
      </Typography>
      <Button skin="link" className={classes.fixBtn} disabled={!isUpdateEnabled || mergeInProgress} onClick={onMerge}>
        <FormattedMessage defaultMessage="Merge" description="Button label to merge duplicate addresses into one" />
        {mergeInProgress && (
          <Spinner
            className={classes.mergePreloader}
            accessibleText={intl.formatMessage({ defaultMessage: 'Merging duplicates in progress', description: 'Label for Merging duplicate addresses' })}
          />
        )}
      </Button>
    </FlexBox>
  )
}

const DifferentPersonHeading: FC<{ count: number; groupId: string }> = ({ count, groupId }) => {
  const { onSelect } = useAddressActions()
  return (
    <FlexBox justifyContent="space-between" alignItems="center" style={{ width: '100%' }} px={{ xs: 5, sm: 5, md: 6, lg: 6 }} pt={5}>
      <Typography fontSize={'small'} textColor="standard" fontWeight="bold" px={3} py={1} className={classes.badge}>
        <FormattedMessage
          defaultMessage="Recipients with the same address"
          description="Label to show recipients with the same address"
          values={{
            addressLength: count,
          }}
        />
      </Typography>
      <Button skin="link" className={classes.fixBtn} onClick={() => onSelect(groupId)}>
        <FormattedMessage defaultMessage="Fix" description="Button label to select one of the duplicate addresses" />
      </Button>
    </FlexBox>
  )
}

export const DuplicateAddressTabContent: FC<{
  addresses: DuplicatedAddressGroup[]
  isPreviewLoading: boolean
}> = ({ addresses, isPreviewLoading }) => {
  const { currentPage, pageSize, selection, onReset } = useAddressSelection()
  const displayAddresses = useMemo(
    () => addresses?.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize),
    [addresses, currentPage, pageSize],
  )
  useEffect(() => {
    onReset(addresses.flatMap(getIdsForDuplicates))
  }, [onReset, addresses])
  return (
    <>
      {displayAddresses.map(addressGroup => {
        const groupId = getIdForDuplicateGroup(addressGroup)
        return (
          <Box key={groupId} className={classes.duplicateGroup}>
            <FlexBox justifyContent="space-between" alignItems="center">
              {addressGroup.samePerson ? (
                <SamePersonHeading count={addressGroup.addresses.length} addressGroup={addressGroup} />
              ) : (
                <DifferentPersonHeading count={addressGroup.addresses.length} groupId={groupId} />
              )}
            </FlexBox>
            {addressGroup.addresses.map((address, index, allAddresses) => {
              const rowId = getIdForAddressDetail(address)
              return (
                <AddressRow
                  type={AddressType.DUPLICATE}
                  key={`${groupId}:${rowId}`}
                  rowId={rowId}
                  isSelected={selection[rowId]}
                  address={address}
                  showDivider={index < allAddresses.length - 1}
                  isPreviewLoading={isPreviewLoading}
                />
              )
            })}
          </Box>
        )
      })}
    </>
  )
}

export const DuplicateAddressTab: FC<{
  addresses: DuplicatedAddressGroup[]
  isPreviewLoading: boolean
}> = ({ addresses, isPreviewLoading }) => (
  <ErrorBoundary>
    <AddressActionsProvider addressType={AddressType.DUPLICATE}>
      <AddressSelectionProvider addressType={AddressType.DUPLICATE} totalAddressCount={addresses.length}>
        <DuplicateAddressTabContent addresses={addresses} isPreviewLoading={isPreviewLoading} />
      </AddressSelectionProvider>
    </AddressActionsProvider>
  </ErrorBoundary>
)
