/*
  This regex will catch all latin and non latin ascii and unicode characters, digits, and accepted symbols.
  It does happen to whitelist some of the more odd unicode punctuation. May need to refine.

  \u00A0-\uD7FF Matches a character in the range " " to "퟿" (char code 160 to 55295). Case sensitive.
  \uF900-\uFDCF Matches a character in the range "豈" to "﷏" (char code 63744 to 64975). Case sensitive.
  \uFDF0-\uFFEF Matches a character in the range "ﷰ" to "￯" (char code 65008 to 65519). Case sensitive.
*/
import { Config } from 'components/address-form/field-config.hook'

const addressInputRegex = /^[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF\w\d\s@#\-+,.'&/]*$/

/*
Max limit of characters (i.e. for first and last name fields).
*/
export const MAX_LIMIT = 33
export const EXTENDED_MAX_LIMIT = 40

/*
Min limit of characters for first + last name (or company)
*/
export const MIN_LIMIT = 3

export const requiredFieldValidator = (required: boolean) => {
  if (required) {
    return 'invalidAddressEntryEmpty'
  }
  return undefined
}

const invalidCharactersValidator = (value: string) => {
  // This regex will catch all latin and non latin ascii and unicode characters, digits, and accepted symbols.
  // It does happen to whitelist some of the more odd unicode punctuation. May need to refine.
  if (!addressInputRegex.test(value)) {
    return 'invalidAddressEntryCharacters'
  }

  return undefined
}

const maxLengthValidator = (value: string, limit: number) => {
  if (value.length > limit) {
    return limit === EXTENDED_MAX_LIMIT ? 'invalidAddressEntryLengthExtended' : 'invalidAddressEntryLength'
  }
  return undefined
}

const validateZipCode = (zipCode: string, locale: string) => {
  let zipCodeRegex
  const localeLowerCase = locale.toLocaleLowerCase()
  if (localeLowerCase === 'en-us' || localeLowerCase === 'es-us') {
    // US zip code regex: 5 digits, optional hyphen, and 4 more digits
    zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  } else if (localeLowerCase === 'en-ca' || localeLowerCase === 'fr-ca') {
    // Canadian postal code regex: letter, digit, letter, space, digit, letter, digit
    zipCodeRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/
  } else {
    return ''
  }

  return zipCodeRegex.test(zipCode) ? undefined : 'invalidPostalCode'
}

export function validatorFactory(config: Pick<Config, 'isOptional' | 'key'>, locale: string) {
  return function (value: string): string | undefined {
    let error
    if (!value) {
      error = requiredFieldValidator(!config.isOptional)
    } else if (value && config.key === 'postalCode') {
      error = validateZipCode(value, locale)
    } else if (value && config.key === 'lastName') {
      error = maxLengthValidator(value, EXTENDED_MAX_LIMIT) || invalidCharactersValidator(value)
    } else if (value && config.key === 'company') {
      error = maxLengthValidator(value, EXTENDED_MAX_LIMIT) || invalidCharactersValidator(value)
    } else {
      error = maxLengthValidator(value, MAX_LIMIT) || invalidCharactersValidator(value)
    }
    return error
  }
}
