import { Icon, ModalDialog, ModalDialogCloseButton, ModalDialogContent, ModalDialogHeader, ModalDialogNav, ModalDialogTitle } from '@vp/swan'
import { SectionLoaderWrapper } from 'components/loader/loader.component'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useAddressList, useAddressListUpdate } from 'lib/address-list'
import { withErrorBoundary } from 'lib/errors'
import { useMsgAddressEditInformation } from 'lib/intl/msg-address-dialog.hooks'
import { useMsgEditRecipients } from 'lib/intl/msg-edit-action.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useToastIncorrectAddress, useToastPositive } from 'lib/toast/toast.hooks'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { RowAddressActionParams } from 'modules/review/types/address-multi-selection.types'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UseMutateFunction } from 'react-query'
import { UpdateAddressRequest, UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { noOp } from 'utilities/functions.utils'
import { EditableAddress, getEditableAddresses } from './edit-addresses.util'
import { PostEdit } from './post-edit.component'
import { SingleRowEdit } from './single-row-edit.component'
import { FORM_TYPES } from 'components/address-form/address-form.constants'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

const EditAddressesInternal: FC<
  Omit<RowAddressActionParams, 'isOpen'> & {
    update: UseMutateFunction<UploadedAddressesResponse, unknown, { request: UpdateAddressRequest }, unknown>
    inProgress: boolean
    hasError: boolean
    onExit: () => void
  }
> = ({ type, ids, onExit, update, inProgress, hasError }) => {
  const [addressesToEdit, setAddressToEdit] = useState<EditableAddress[]>([])
  const [hasToReset, setHasToReset] = useState<boolean>(false)
  const { data: addresses } = useAddressList()
  const unknownErrorToast = useToastSomethingWentWrong()
  const positiveToast = useToastPositive()
  const workId = useWorkIdQueryParam()
  const successMessage = useMsgEditRecipients()
  const incorrectAddressToast = useToastIncorrectAddress()
  const { logError } = useLogger()

  const saveAddress = (address: IAddressDetails) => {
    const newAddresses = [
      {
        ...addressesToEdit[0],
        toEdit: address,
      },
    ]
    const updateAddressRequestBody: UpdateAddressRequest = {
      addressesToUpdate: newAddresses.map(u => u.toEdit),
      addressIndexesToRemove: [],
      duplicatedAddressIndexesToPromoteAsValid: [],
    }
    update(
      { request: updateAddressRequestBody },
      {
        onSuccess: () => {
          positiveToast(successMessage)
          newRelicAddPageAction('Save edited addresses', { result: 'success' })
          onExit()
          setHasToReset(true)
          setHasToReset(false)
        },
        onError: error => {
          const response = JSON.stringify(error)
          if (response.includes('422')) incorrectAddressToast()
          else unknownErrorToast()
          newRelicAddPageAction('Save edited addresses', { result: 'error' })
          logError('Error occurred while editing addresses', { contextData: { workId, type } })
        },
      },
    )
  }

  useEffect(() => {
    setAddressToEdit(getEditableAddresses(addresses, ids, type))
  }, [addresses, ids, type])
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  return (
    <>
      {addressesToEdit.length ? (
        <>
          <ModalDialogHeader>
            <ModalDialogTitle>
              {!loadingManualAddressingFlag &&
                (manualAddressingFeatureToggle ? (
                  <FormattedMessage defaultMessage="Edit recipient" description="Title for the recipient to be edited" />
                ) : (
                  <FormattedMessage defaultMessage="Edit contact" description="Title for the contact to be edited" />
                ))}
            </ModalDialogTitle>
          </ModalDialogHeader>
          <SingleRowEdit
            formType={FORM_TYPES.ADDRESS}
            editInProgress={inProgress}
            suggestion={addressesToEdit[0].suggested}
            address={addressesToEdit[0].toEdit}
            onUpdate={saveAddress}
            showError={hasError}
            onExit={onExit}
            hasToReset={hasToReset}
          />
        </>
      ) : null}
    </>
  )
}

const EditContainer: FC<RowAddressActionParams> = ({ ids, isOpen, onExit, type }) => {
  const {
    isEnabled,
    mutationResult: { mutate, isLoading, isError },
    errorFlag,
  } = useAddressListUpdate(false, false, ids)
  const unknownErrorToast = useToastSomethingWentWrong()
  const incorrectAddressToast = useToastIncorrectAddress()
  const [postEditIds, setPostEditRowIds] = useState<number[]>([])
  const addressEditInformation = useMsgAddressEditInformation()
  const modalCloseLabel = useMsgModalClose()
  const workId = useWorkIdQueryParam()
  const { logError } = useLogger()

  const exitOnPostEdit = () => {
    onExit()
  }
  const onBack = () => {
    setPostEditRowIds([])
  }
  useEffect(() => {
    if (isError) {
      if (errorFlag) {
        incorrectAddressToast()
      } else {
        unknownErrorToast()
      }
      logError('Error occurred while editing addresses', { contextData: { workId, type } })
    }
  }, [isError, unknownErrorToast, workId, type, errorFlag, logError])
  return (
    <ModalDialog variant="panel-right" isOpen={isOpen} onRequestDismiss={noOp} bodyWidth="capped">
      <SectionLoaderWrapper showLoader={isLoading || !isEnabled}>
        <ModalDialogContent aria-labelledby={addressEditInformation} backgroundColor="standard">
          <ModalDialogNav>
            {postEditIds?.length ? <Icon skin="standard" iconType="arrowLeft" pr={2} style={{ cursor: 'pointer' }} onClick={onBack} /> : null}

            <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} onClick={onExit} />
          </ModalDialogNav>
          {!postEditIds?.length ? (
            <EditAddressesInternal
              ids={ids}
              // onExit={setPostEditRowIds} - removing because we do not support bulk edit
              onExit={onExit}
              type={type}
              update={mutate}
              inProgress={isLoading}
              hasError={isError}
            />
          ) : (
            <PostEdit ids={postEditIds} onExit={exitOnPostEdit} update={mutate} />
          )}
        </ModalDialogContent>
      </SectionLoaderWrapper>
    </ModalDialog>
  )
}

export const EditAddresses = withErrorBoundary(EditContainer)
