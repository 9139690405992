import { Box } from '@vp/swan'
import { QUERY_KEY_MAILING_LIST_ID } from 'constants/query-key.constants'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'
import { useQueryParam } from 'contexts/query-param.context'
import { getInitialValue } from 'hooks/use-query-string-state.hook'
import { AddressType, MultiSelectionAction, MultiSelectionStatus, MultiSelectionValue } from 'modules/review/types/address-multi-selection.types'
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useReducer, useState } from 'react'
import { noOp } from 'utilities/functions.utils'
import { PaginationComponent } from '../pagination/pagination.component'
import { AddressTabHeaderSaveFlow } from './address-tab-header-save-flow.component'
import { AddressTabHeader } from './address-tab-header.component'

const PAGE_SIZE = 100
export type AddressSelectionContextValue = {
  currentPage: number
  pageSize: number
  selection: MultiSelectionValue
  onToggle: (key: string) => void
  onReset: (keys: string[]) => void
  toggleStatus: () => void
  selectedKeys: string[]
  status: MultiSelectionStatus
}
export const AddressSelectionContext = createContext<AddressSelectionContextValue>({
  currentPage: 1,
  pageSize: PAGE_SIZE,
  selection: {},
  onToggle: noOp,
  onReset: noOp,
  toggleStatus: noOp,
  selectedKeys: [],
  status: MultiSelectionStatus.NONE,
})

export type AddressSelectionProviderProps = {
  addressType: AddressType
  totalAddressCount: number
}

function multiSelectionReducer(state: MultiSelectionValue, action: MultiSelectionAction): MultiSelectionValue {
  const emptyInitial = {} as MultiSelectionValue

  switch (action.type) {
    case 'toggle':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    case 'reset':
      return action.values.reduce((_agg, key) => {
        _agg[key] = state[key] || false
        return _agg
      }, emptyInitial)
    case 'selectAll':
    case 'selectNone':
    default:
      return Object.keys(state).reduce((_agg, key) => {
        _agg[key] = action.type === 'selectAll'
        return _agg
      }, emptyInitial)
  }
}

export const AddressSelectionProvider: FC<PropsWithChildren<AddressSelectionProviderProps>> = ({ children, addressType, totalAddressCount }) => {
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const isSavedListFlow = getInitialValue(QUERY_KEY_MAILING_LIST_ID, '')
  const [state, dispatch] = useReducer(multiSelectionReducer, {})
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = Number(useQueryParam('pageSize')) || PAGE_SIZE
  const [selectedKeys, status] = useMemo(() => {
    const allKeys = Object.keys(state)
    const allSelectedKeys = allKeys.filter(k => state[k])
    if (allSelectedKeys.length === 0) return [allSelectedKeys, MultiSelectionStatus.NONE]
    if (allKeys.length === allSelectedKeys.length) return [allSelectedKeys, MultiSelectionStatus.ALL]
    return [allSelectedKeys, MultiSelectionStatus.SOME]
  }, [state])

  const onToggle = useCallback((key: string) => {
    dispatch({
      type: 'toggle',
      key,
    })
  }, [])

  const toggleStatus = useCallback(() => {
    switch (status) {
      case MultiSelectionStatus.ALL:
      case MultiSelectionStatus.SOME:
        dispatch({
          type: 'selectNone',
        })
        break
      case MultiSelectionStatus.NONE:
      default:
        dispatch({
          type: 'selectAll',
        })
        break
    }
  }, [status])

  const onReset = useCallback((values: string[]) => {
    dispatch({
      type: 'reset',
      values,
    })
  }, [])

  const value = useMemo(
    () => ({
      currentPage,
      pageSize,
      selection: state,
      onToggle,
      onReset,
      toggleStatus,
      selectedKeys,
      status,
    }),
    [currentPage, state, onToggle, onReset, toggleStatus, selectedKeys, pageSize, status],
  )
  const forSavedListFlow = !!isSavedListFlow && manualAddressingFeatureToggle
  return (
    <AddressSelectionContext.Provider value={value}>
      <Box>
        {!loadingManualAddressingFlag &&
          (forSavedListFlow ? <AddressTabHeaderSaveFlow addressType={addressType} /> : <AddressTabHeader addressType={addressType} />)}
        <Box px="0">
          {children}
          <PaginationComponent
            currentPage={currentPage}
            totalCount={totalAddressCount}
            pageSize={pageSize}
            onPageChange={pageNumber => setCurrentPage(pageNumber)}
          />
        </Box>
      </Box>
    </AddressSelectionContext.Provider>
  )
}

export function useAddressSelection() {
  return useContext(AddressSelectionContext)
}
