import { IAddressDetails } from './address-list.types'
import { AddressType } from './address-multi-selection.types'

export enum PreviewType {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
}

export interface PreviewActionConfig {
  rowIndex: string
  address: IAddressDetails
  addressType: AddressType
}

export interface AddressesForPreviewButtons {
  current: IAddressDetails | undefined
  next: IAddressDetails | undefined
  previous: IAddressDetails | undefined
  addressType: AddressType
}
