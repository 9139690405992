import { useIntl } from 'react-intl'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

export function useMsgAddressFormatAriaLabel() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'See address Format Information',
    description: 'Label to see the address format information',
  })
}
export function useMsgConfirmDeliverability() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Confirm deliverability',
    description: 'Checklist task that is performed by Mailing Services after uploading addresses',
  })
}

export function useMsgStandardize() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Standardize their address format',
    description: 'Checklist task that is performed by Mailing Services after uploading addresses',
  })
}

export function useMsgZip() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Add ZIP+4 where necessary',
    description: 'Checklist task that is performed by Mailing Services after uploading addresses',
  })
}

export function useMsgLessRecipientList(envelopeCount: number) {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()

  if (loadingManualAddressingFlag) {
    return ''
  }

  if (manualAddressingFeatureToggle) {
    return intl.formatMessage(
      {
        defaultMessage:
          'You added {envelopeCount} envelopes to your order. Add a recipient for each one. Excess envelopes will be sent without recipient addressing.',
        description: 'Message to start adding addresses when list is empty',
      },
      { envelopeCount },
    )
  }

  return intl.formatMessage(
    {
      defaultMessage: 'You added {envelopeCount} envelopes to your order. Add a recipient for each one. Excess envelopes will be sent blank.',
      description: 'Message to start adding addresses when list is empty',
    },
    { envelopeCount },
  )
}

export function useListGoodToGoMsg() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Your list is good to go.',
    description: 'Heading when address validations are done',
  })
}
