import { getIsPreviewReady } from 'lib/mailing-svc-api/mailing-service.api'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useAccessToken } from 'lib/user'
import { useWorkDetails } from 'lib/work-entity'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import _ from 'lodash'
export function usePreview() {
  const [loading, setLoading] = useState(false)
  const token = useAccessToken()
  const { data: workDetails } = useWorkDetails()
  const designUrl = workDetails?.design.designUrl
  const customEnvelopeDimension = workDetails?.merchandising.merchandisingSelections?.Size
  const productKey = workDetails?.product.key
  const somethingWentWrong = useToastSomethingWentWrong()

  const handleMutation = async ({ address }: { address?: IAddressDetails }) => {
    if (designUrl && token && address && productKey && customEnvelopeDimension) {
      setLoading(true)

      try {
        const isPreviewReady = await getIsPreviewReady({
          designUrl,
          address,
          token,
          coreProductId: productKey,
          customEnvelopeDimension,
        })
        if (isPreviewReady.isPreviewReady) {
          setLoading(false)
          return true
        }
      } catch (e) {
        setLoading(false)
        somethingWentWrong()
      }
    }
  }
  const mutationResult = useMutation(handleMutation)
  return useMemo(() => ({ loading, mutationResult }), [loading, mutationResult])
}
