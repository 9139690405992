import { FlexBox, H1, Icon, Li, Typography, Ul } from '@vp/swan'
import { TabularShimmer } from 'components/loader/shimmer/shimmer.component'
import { useRequestIdQueryParam } from 'contexts/query-param.context'
import { Error } from 'lib/errors'
import { useMsgErrorWhileFetching } from 'lib/intl/msg-errors.hooks'
import {
  useMsgDuplicateAddress,
  useMsgFixedDuplicates,
  useMsgFixedInvalids,
  useMsgInvalidAddress,
  useMsgMoreEnvelopes,
  useMsgValidAddress,
  useNeedMoreEnvelopesMsg,
} from 'lib/intl/msg-validation-header.hooks'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { useProductInfoQtySelection } from 'lib/product-info'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import classes from './validation-header.module.scss'
import { useListGoodToGoMsg } from 'lib/intl/msg-address-format-info.hooks'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

type ExcelUploadProgressProps = {
  valid: number
  duplicate: number
  invalid: number
  isLoading: boolean
  isError: boolean
  isIdle: boolean
  isSuccess: boolean
  total: number
  initialInvalids: number
  initialDuplicates: number
}

type ExcelUploadProgressType = {
  label: string
  image: JSX.Element
  count: number
}

export const ValidationHeader: FC<ExcelUploadProgressProps> = ({
  valid,
  duplicate,
  invalid,
  isLoading,
  isError,
  isIdle,
  isSuccess,
  total,
  initialInvalids,
  initialDuplicates,
}) => {
  const duplicateLabel = useMsgDuplicateAddress(duplicate)
  const invalidLabel = useMsgInvalidAddress(invalid)
  const validLabel = useMsgValidAddress(valid, total)
  const errorWhileFetching = useMsgErrorWhileFetching()
  const requestId = useRequestIdQueryParam()
  const initialInvalidLabel = useMsgFixedInvalids(initialInvalids)
  const initialDuplicateLabel = useMsgFixedDuplicates(initialDuplicates)
  const { selectedQty } = useProductInfoQtySelection()
  const excessEnvelopesLabel = useMsgMoreEnvelopes(selectedQty, valid)
  const excessAddressesLabel = useNeedMoreEnvelopesMsg(valid, selectedQty)
  const listGoodToGoMsg = useListGoodToGoMsg()
  const { logError } = useLogger()

  const uploadProgressData: ExcelUploadProgressType[] = [
    {
      label: validLabel,
      image: <Icon iconType="success" size="20p" skin="success" />,
      count: valid,
    },
    {
      label: duplicateLabel,
      image: <Icon iconType="warning" size="20p" skin="warning" />,
      count: duplicate,
    },
    {
      label: invalidLabel,
      image: <Icon iconType="error" size="20p" skin="error" />,
      count: invalid,
    },
  ]

  useEffect(() => {
    if (isError) {
      logError('Error occurred while fetching addresses', { contextData: { requestId } })
    }
  }, [isError, requestId, logError])

  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  return (
    <section>
      {isError && <Error message={errorWhileFetching} align="left" />}
      <H1 fontSkin="title-headline" style={{ textAlign: 'left' }}>
        {!loadingManualAddressingFlag &&
          (valid && !duplicate && !invalid ? (
            listGoodToGoMsg
          ) : manualAddressingFeatureToggle ? (
            <FormattedMessage defaultMessage="Let's review your recipients" description="Heading to validate the addresses" />
          ) : (
            <FormattedMessage defaultMessage="Let's review your contacts" description="Heading to validate the addresses" />
          ))}
      </H1>
      {(isIdle || isLoading) && <TabularShimmer className={classes.shimmerContainer} mt={5} rows={1} cols={3} />}
      {!isError && !isLoading && isSuccess && (
        <FlexBox flexDirection="column">
          {uploadProgressData
            .filter(u => u.count)
            .map(unit => {
              if (unit.label)
                return (
                  <FlexBox key={unit.label} py={3}>
                    {unit.image}
                    <Typography fontSkin="body-standard" textAlign="left" mr={{ xs: 4, sm: 4, md: 5, lg: 6 }} ml={3}>
                      {unit.label}
                    </Typography>
                  </FlexBox>
                )
            })}
          {!duplicate && !invalid && (initialInvalids || initialDuplicates) ? (
            <FlexBox pt={3} pl={3}>
              <Ul>
                {initialInvalids ? <Li>{initialInvalidLabel}</Li> : null}
                {initialDuplicates ? <Li>{initialDuplicateLabel}</Li> : null}
              </Ul>
            </FlexBox>
          ) : null}
          {!duplicate && !invalid && selectedQty > valid ? (
            <FlexBox py={2}>
              <Typography fontSkin="body-standard" textAlign="left" mr={{ xs: 4, sm: 4, md: 5, lg: 6 }}>
                {excessEnvelopesLabel}
              </Typography>
            </FlexBox>
          ) : null}
          {!duplicate && !invalid && valid > selectedQty ? (
            <FlexBox py={2}>
              <Icon iconType="error" size="20p" skin="error" />
              <Typography fontSkin="body-standard" textAlign="left" mr={{ xs: 4, sm: 4, md: 5, lg: 6 }} ml={3}>
                {excessAddressesLabel}
              </Typography>
            </FlexBox>
          ) : null}
        </FlexBox>
      )}
    </section>
  )
}
