import { Box } from '@vp/swan'
import { FullPageLoader } from 'components/loader/loader.component'
import { FC } from 'react'
import { StrategySelectionSet } from './strategy-set.component'
import { useAutoFix } from './use-auto-fix.hook'
import { useStrategySelectorChangeHandler } from './use-strategy-change.hook'

export const Strategize: FC<{ onChange: () => void; areAllValid: boolean; isIdle: boolean }> = ({ onChange, isIdle, areAllValid }) => {
  const { isAutoFixInProgress, fix } = useAutoFix()
  const { inProgress, disabled, changeStrategy, strategy } = useStrategySelectorChangeHandler(fix, onChange)
  const renderer = (
    <Box marginY="to-actions">
      <StrategySelectionSet strategy={strategy} changeStrategy={changeStrategy} disabled={disabled} />
    </Box>
  )
  if (isIdle) return renderer
  if (!isIdle && areAllValid && !strategy) return null
  return (
    <>
      {(inProgress || isAutoFixInProgress) && <FullPageLoader />}
      {renderer}
    </>
  )
}
