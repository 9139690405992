import { useEffect, useState } from 'react'
import {
  FlexBox,
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  TextInput,
  Spinner,
  Typography,
} from '@vp/swan'
import { withErrorBoundary } from 'lib/errors'
import { FormattedMessage, useIntl } from 'react-intl'

import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { useGetErrorStatus } from 'modules/review/utilities/address.utils'

type EditFilenameParams = {
  isOpen: boolean
  onClose: () => void
  handleSave: (fileName: string | undefined) => void
  initialFileName: string | undefined
}

const EditFilenameInternal: React.FC<EditFilenameParams> = ({ onClose, handleSave, isOpen, initialFileName }) => {
  const modalCloseLabel = useMsgModalClose()
  const [isEditing, setIsEditing] = useState(false)
  const [fileName, setFileName] = useState('')
  const [userHasEnteredText, setUserHasEnteredText] = useState(false)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value)
    setUserHasEnteredText(true)
  }
  const intl = useIntl()

  const errorTranslations = {
    emptyNameErrorMessage: intl.formatMessage({
      defaultMessage: 'Please provide a new name for the mailing list.',
      description: 'Please provide a new name for the mailing list.',
    }),
    nameAlreadyExistsErrorMessage: intl.formatMessage({
      defaultMessage: 'Name already exists.',
      description: 'Name already exists.',
    }),
    nameTooLongErrorMessage: intl.formatMessage({
      defaultMessage: 'Name too long. Please provide less than 64 characters.',
      description: 'Name too long. Please provide less than 64 characters.',
    }),
    invalidCharactersErrorMessage: intl.formatMessage({
      defaultMessage: 'Please provide alphanumeric characters (_ and - are allowed)',
      description: 'Please provide alphanumeric characters (_ and - are allowed)',
    }),
  }

  const saveHandler = async () => {
    setIsEditing(true)
    await handleSave(fileName)
  }

  useEffect(() => {
    if (isEditing === true) setIsEditing(false)
  }, [isOpen])

  const { isValid, errorMessage } = useGetErrorStatus({
    currentName: fileName,
    localizedMessages: errorTranslations,
    userHasEnteredText,
  })
  return (
    <ModalDialog variant="panel-right" isOpen={isOpen} onRequestDismiss={onClose}>
      <ModalDialogContent p={7} display="flex" style={{ flexDirection: 'column', justifyContent: 'space-between', width: 450 }} aria-labelledby="">
        <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
        <ModalDialogHeader mb={5}>
          <ModalDialogTitle>
            <FormattedMessage defaultMessage="Rename address list" description="Header for renaming saved list name" />
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody style={{ flex: 1 }}>
          <FlexBox py={5}>
            <TextInput value={fileName} onChange={handleInputChange} autoFocus size="standard" placeholder={initialFileName} />
          </FlexBox>
        </ModalDialogBody>
        {!isValid && (
          <Typography textColor="error" fontSize={'small'} ml={2}>
            {errorMessage}
          </Typography>
        )}
        <ModalDialogButtons>
          <Button skin="primary" width="full-width" onClick={saveHandler} disabled={!(userHasEnteredText && isValid) || isEditing}>
            {isEditing && <Spinner size="tiny" accessibleText="Submitting..." />}
            <FormattedMessage defaultMessage="Save" description="Button label for Saving Filename" />
          </Button>
        </ModalDialogButtons>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export const EditFilename = withErrorBoundary(EditFilenameInternal)
