import { useResponsiveProps } from '@vp/swan'
import { getPurcsViews } from 'components/work-id-preview/purcs-api'
import { WorkDetails } from 'components/work-id-preview/work-id-preview.types'
import { useAccessToken } from 'lib/user'
import { useWorkDetails } from 'lib/work-entity'
import { useQuery } from 'react-query'
import { failedApiPromiseForIncompleteArguments } from 'utilities/api.utility'
import { useLocalization } from '../../hooks/use-localization'
import { useLogger } from '@vp/shared-capabilities-component-library/components'

export type PurcsConfig = {
  purpose?: string
  workDetails?: Partial<WorkDetails>
}

export function usePurcsSlides({ purpose, workDetails: overrideWorkDetails }: PurcsConfig) {
  const token = useAccessToken()
  const { data: workDetailsFromURL } = useWorkDetails()
  const workDetails = overrideWorkDetails || workDetailsFromURL
  const { locale } = useLocalization()
  // width will be half of the upper limit of the screen class
  const widthProps = useResponsiveProps({
    lg: { width: 1440 },
    md: { width: 720 },
    sm: { width: 600 },
    xs: { width: 400 },
    width: 1440,
  })
  const { logError } = useLogger()

  return useQuery(
    ['purcs', workDetails?.workId, workDetails?.product, widthProps.width],
    ({ signal }) => {
      if (workDetails?.product && workDetails?.merchandising && workDetails?.design && widthProps.width && locale && token) {
        return getPurcsViews({
          productKey: workDetails.product.key,
          productVersion: workDetails.product.version,
          previewInstructionsUri: workDetails.design.displayUrl,
          locale,
          optionSelections: workDetails.merchandising.merchandisingSelections,
          width: widthProps.width,
          purpose,
          token,
          signal,
        })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!(workDetails?.product && workDetails?.merchandising && workDetails?.design && token),
      cacheTime: 0,
      staleTime: 0,
      onError: error => {
        logError('Error occurred while fetching purcs slides', { contextData: { error } })
      },
    },
  )
}
