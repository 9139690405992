/* eslint-disable no-console */
import { FlexBox, Spinner } from '@vp/swan'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'
import { useNavigateToReview } from 'hooks/navigate.hooks'
import { isNewListFlow } from 'hooks/use-query-string-state.hook'
import { useNewMailingListCreate } from 'lib/address-list'
import { useDisplayListName } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { ReactNode, FC, useEffect } from 'react'
import { useDateTimeSet } from 'utilities/functions.utils'
import classes from './review-list-wrapper.module.scss'

interface ReviewListWrapperProps {
  children: ReactNode
}

export const ReviewListWrapper: FC<ReviewListWrapperProps> = ({ children }) => {
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const navigateToReview = useNavigateToReview()
  const LoadingText = useMsgModalLoadingText()
  const listName = useDisplayListName()
  const dateTimeVal = useDateTimeSet()
  const isNewListFlowVerify = isNewListFlow()

  const {
    isEnabled,
    mutationResult: { mutateAsync: createNewMailingList },
  } = useNewMailingListCreate()

  useEffect(() => {
    if (isNewListFlowVerify && isEnabled && !loadingManualAddressingFlag && manualAddressingFeatureToggle) {
      createMailingList(listName + ' ' + dateTimeVal)
    }
  }, [loadingManualAddressingFlag, isEnabled])

  const createMailingList = async (mailingListName: string) => {
    await createNewMailingList(
      { name: mailingListName },
      {
        onSuccess: data => {
          const { mailingListId } = data
          navigateToReview(mailingListId, 'UPLOADED', true)
        },
      },
    )
  }
  return isNewListFlowVerify && (loadingManualAddressingFlag || manualAddressingFeatureToggle) ? (
    <FlexBox justifyContent={'center'}>
      <Spinner size="super" accessibleText={LoadingText} className={classes.centerAlignLoader} />
    </FlexBox>
  ) : (
    <>{children}</>
  )
}
