import { Button, Spinner } from '@vp/swan'
import { useMailingListIdQueryParam, useRequestIdQueryParam, useWorkIdQueryParam } from 'contexts/query-param.context'
import { useNavigateToConfirmation } from 'hooks/navigate.hooks'
import { useIsEnvelope, useTrackingProductPageName } from 'hooks/use-product.hook'
import { useAddressList, useAddressListQuery, useMailingListCreate } from 'lib/address-list'
import { withErrorBoundary } from 'lib/errors'
import { useMsgInvalidAddressList } from 'lib/intl/msg-errors.hooks'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { useAddToCart } from 'lib/mailing-svc-api'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useProductInfoQtySelection } from 'lib/product-info/product-info.context'
import { useSiteFlowAddToCart } from 'lib/site-flow'
import { useEventTracking } from 'lib/telemetry'
import { PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useErrorToastWithDuration } from 'lib/toast/toast.hooks'
import { useUpdateWorkDetails, useWorkDetails } from 'lib/work-entity'
import { AddToCartRequest } from 'modules/review/types/confirmation.types'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { windowOpen } from 'utilities/navigate.utils'
import { UpdateQuantity } from '../modal-quantity/update-quantity.component'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

const BtnMailingListCreateInternal: FC<{ mailingListName?: string | null; isAbleToCreateFromWork: boolean }> = ({
  mailingListName = null,
  isAbleToCreateFromWork,
}) => {
  const {
    isEnabled: isListCreationEnabled,
    mutationResult: { mutateAsync: create, isLoading, isSuccess },
  } = useMailingListCreate()
  const unknownErrorToast = useToastSomethingWentWrong()
  const intl = useIntl()
  // const updateSelection = useProductInfoUpdate()
  const { selectedQty } = useProductInfoQtySelection()
  const savedMailingListId = useMailingListIdQueryParam()
  const { data: addresses } = useAddressList()
  const isNextEnabled =
    (isAbleToCreateFromWork || (savedMailingListId && addresses?.validAddresses?.length)) &&
    isListCreationEnabled &&
    mailingListName &&
    !isLoading &&
    !isSuccess
  const [isUpdatingWork, setIsUpdatingWork] = useState(false)
  const [completeMailingListFlaginProgress, setcompleteMailingListFlaginProgress] = useState(false)
  const navigateToConfirmation = useNavigateToConfirmation(true)
  const workId = useWorkIdQueryParam()
  const errorMessage = useMsgInvalidAddressList() as string
  const errorToast = useErrorToastWithDuration(errorMessage)
  const requestId = useRequestIdQueryParam() || undefined
  const { refetch } = useAddressListQuery({ requestId, retryCount: 0 })
  const isEnvelope = useIsEnvelope()
  const [mailingListId, setMailingListId] = useState<string>()
  const fireTracking = useEventTracking()
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [showUpdateQtyModal, setShowUpdateQtyModal] = useState(false)
  const {
    mutationResult: { mutateAsync: addToCart, isError: isErrorAddToCart },
  } = useAddToCart()
  const [cartWorkId, setCartWorkId] = useState<string>()
  const { data: siteFlowNextStep, isLoading: isLoadingSiteFlowNextStep, isError: isErrorSiteFlow } = useSiteFlowAddToCart(cartWorkId)
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)
  const { data: workDetails } = useWorkDetails()
  const {
    mutationResult: { mutateAsync: updateQuantityInWork },
  } = useUpdateWorkDetails()
  const { reEntryFeatureToggle, loadingreEntryFeatureFlag } = useFeatureFlagContext()
  const { logWarn, logError } = useLogger()

  // siteflow will have data only when add to cart API return response with workId
  useEffect(() => {
    if (siteFlowNextStep) {
      windowOpen(siteFlowNextStep.url)
    }
  }, [siteFlowNextStep])

  useEffect(() => {
    if (isErrorSiteFlow) {
      unknownErrorToast()
      logWarn('Add to Cart: Next step in flow unavailable', { contextData: { mailingListId } })
    }
  }, [isErrorSiteFlow, mailingListId, unknownErrorToast, logWarn])

  useEffect(() => {
    if (isErrorAddToCart) {
      unknownErrorToast()
      logError('Error occurred while adding to cart', { contextData: { error: isErrorAddToCart } })
    }
  }, [isErrorAddToCart, unknownErrorToast, logError])

  useEffect(() => {
    if (mailingListId) {
      if (isEnvelope) {
        addEnvelopesToCart()
      } else {
        navigateToConfirmation(mailingListId)
      }
    }
  }, [mailingListId, isEnvelope])

  const addEnvelopesToCart = () => {
    const trackingData = {
      category: TrackingCategoryType.ECOMMERCE,
      label: TrackingLabelType.PRODUCT_ADDED,
      pageName: trackingProductPageName,
    }
    fireTracking(TrackingEventType.PRODUCT_ADDED, trackingData)

    const request: AddToCartRequest = {}
    addToCart({ request, mailingListId })
      .then(res => {
        setCartWorkId(res.workId)
        setIsAddingToCart(false)
      })
      .catch(error => {
        unknownErrorToast()
        setIsAddingToCart(false)
        logError('Error occurred while adding to cart', { contextData: { mailingListId, error } })
      })
  }
  const createMailingList = async () => {
    if (!requestId) return null
    const newAddresses = await refetch()

    if (newAddresses.data?.status === 'invalidData' || (newAddresses.data?.invalidAddresses && newAddresses.data.invalidAddresses.length > 0)) {
      errorToast()
      return
    }
    const data = await create({ mailingListName })
    setIsUpdatingWork(true)
    // updateSelection({ qty: data.addressIds.length })
    newRelicAddPageAction('Create mailing list', { result: 'success' })
    setMailingListId(data.mailingListId)
  }
  const completeMailingListAction = () => {
    if (savedMailingListId) {
      setMailingListId(savedMailingListId)
      setcompleteMailingListFlaginProgress(true)
      setIsUpdatingWork(true)
    } else {
      createMailingList()
    }
  }

  const next = async () => {
    try {
      if (isNextEnabled || savedMailingListId) {
        completeMailingListAction()
      }
    } catch {
      unknownErrorToast()
      newRelicAddPageAction('Create mailing list', { result: 'error' })

      logError('Error occurred while creating mailing list', { contextData: { mailingListName, workId } })
    }
  }

  const toggleQuantityModal = () => {
    setShowUpdateQtyModal(prevState => !prevState)
  }

  const onClickAddToCart = () => {
    if (addresses && addresses.validAddresses && addresses.validAddresses?.length > selectedQty) {
      toggleQuantityModal()
    } else {
      setIsAddingToCart(true)
      if (workDetails?.merchandising) {
        workDetails.merchandising.quantity = Number(selectedQty)
        updateQuantityInWork(workDetails)
      }
      next()
    }
  }

  const onAddToCartFromModal = () => {
    setIsAddingToCart(true)
    if (workDetails?.merchandising) {
      workDetails.merchandising.quantity = Number(selectedQty)
      updateQuantityInWork(workDetails)
    }
    toggleQuantityModal()
    next()
  }
  return (
    <>
      <Button
        disabled={!isNextEnabled || isAddingToCart || isLoadingSiteFlowNextStep || completeMailingListFlaginProgress}
        skin="primary"
        onClick={onClickAddToCart}
      >
        {!isEnvelope ? (
          <FormattedMessage defaultMessage="Next" description="Button label for next page navigation" />
        ) : (
          <>
            {reEntryFeatureToggle && !loadingreEntryFeatureFlag ? (
              <FormattedMessage defaultMessage="Update cart" description="To update the address list to the cart and move to the next page" />
            ) : (
              <FormattedMessage defaultMessage="Add to cart" description="To add the mailing list to the cart and move to the next page" />
            )}
          </>
        )}
        {(isLoading || isUpdatingWork) && (
          <Spinner
            ml={3}
            mr={0}
            accessibleText={intl.formatMessage({
              defaultMessage: 'Add mailing list to cart in progress',
              description: 'Label for Adding mailing list to cart',
            })}
          />
        )}
      </Button>
      <UpdateQuantity
        isOpen={showUpdateQtyModal}
        onClose={toggleQuantityModal}
        onAddToCart={onAddToCartFromModal}
        addressesInList={addresses?.validAddresses?.length || 0}
        selectedEnvelopesQty={selectedQty}
        disableATCBtn={
          !isNextEnabled ||
          isAddingToCart ||
          isLoadingSiteFlowNextStep ||
          (addresses && addresses.validAddresses && addresses.validAddresses?.length > selectedQty)
        }
      />
    </>
  )
}

export const BtnMailingListCreate = withErrorBoundary(BtnMailingListCreateInternal)
