import { Button, Checkbox, FlexBox, Icon, Spinner, Typography } from '@vp/swan'
import classNames from 'classnames'
import { useDeletionInProgress } from 'lib/intl/msg-address-dialog.hooks'
import { useMsgAlternateTextDeleteLogo } from 'lib/intl/msg-alternate-texts.hooks'
import { useAddressTabHeaderMessage } from 'modules/review/components/address-tab/address-tab-header.hook'
import { useOnDeleteHook } from 'modules/review/components/modal-delete/on-delete.hook'
import { AddressType, MultiSelectionStatus } from 'modules/review/types/address-multi-selection.types'
import { FC, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { AddButton } from './add-button.component'
import { useAddressSelection } from './address-selection.context'
import headerClasses from './address-tab-header.module.scss'
import classes from './address-tab.module.scss'
import { QUERY_KEY_MAILING_LIST_ID } from 'constants/query-key.constants'
import { getInitialValue } from 'hooks/use-query-string-state.hook'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

const SelectionCheckbox: FC<{ message: string }> = ({ message }) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const { toggleStatus, status } = useAddressSelection()

  useEffect(() => {
    if (!checkboxRef.current) return undefined
    if (status === MultiSelectionStatus.SOME) {
      checkboxRef.current.indeterminate = true
      checkboxRef.current.checked = false
    } else {
      checkboxRef.current.indeterminate = false
      checkboxRef.current.checked = status === MultiSelectionStatus.ALL
    }
  }, [status])

  return (
    <>
      <label htmlFor={message}></label>
      <Checkbox id={message} ref={checkboxRef} onClick={toggleStatus} mr={{ xs: 5, sm: 5, md: 7, lg: 7 }} my={3} title={message} />
    </>
  )
}

export const AddressTabHeader: FC<{ addressType: AddressType }> = ({ addressType }) => {
  const isSavedListFlow = getInitialValue(QUERY_KEY_MAILING_LIST_ID, '')
  const deleteAddressMessage = useDeletionInProgress()
  const { selectedKeys } = useAddressSelection()
  const { onDelete, isLoading, isEnabled } = useOnDeleteHook(selectedKeys, addressType)
  const messages = useAddressTabHeaderMessage(addressType, selectedKeys.length)
  const alternateTextDeleteLogo = useMsgAlternateTextDeleteLogo()
  const disabledCheck = !(!selectedKeys.length || (!isEnabled && isLoading))
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const listHeaderRef = useRef<HTMLElement>(null)

  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      bgc="standard"
      paddingY={4}
      px={{ xs: 5, sm: 5, md: 6, lg: 6 }}
      className={classes.headerShadow}
      ref={listHeaderRef}
    >
      <FlexBox alignItems="center">
        <SelectionCheckbox message={selectedKeys.length ? messages.checkboxTitleUnSelectAll : messages.checkboxTitleSelectAll} />
        <Typography fontSize={'standard'} as="span">
          {selectedKeys.length ? messages.addressSelection : <FormattedMessage defaultMessage="Select all" description="Label for selecting all addresses" />}
        </Typography>
      </FlexBox>
      {!loadingManualAddressingFlag &&
        (manualAddressingFeatureToggle ? (
          <AddButton disabledCheck={disabledCheck} px={3} marginLeft="auto" showIcon={true}>
            <FormattedMessage defaultMessage="Add recipient" description="Label for add recipient button" />
          </AddButton>
        ) : (
          <AddButton disabledCheck={disabledCheck} px={3} marginLeft="auto" showIcon={true}>
            <FormattedMessage defaultMessage="Add" description="Label for add button" />
          </AddButton>
        ))}
      {selectedKeys.length > 0 &&
        !loadingManualAddressingFlag &&
        (isSavedListFlow && manualAddressingFeatureToggle ? (
          <h1></h1>
        ) : (
          <div>
            <Button
              disabled={!selectedKeys.length || !isEnabled || isLoading}
              onClick={onDelete}
              title={messages.deleteActionButtonTitle}
              px={3}
              fontWeight="bold"
            >
              <Icon
                iconType="delete"
                size="20p"
                skin="standard"
                alt={alternateTextDeleteLogo}
                className={classNames([isLoading ? headerClasses.fadedLogo : ''])}
              />

              <Typography fontSize={'small'} ml={2} className={classNames([isLoading ? headerClasses.fadedLogo : ''])}>
                <FormattedMessage defaultMessage="Delete" description="Button label to delete an address" />
              </Typography>
              {isLoading && <Spinner className={headerClasses.deletePreloader} accessibleText={deleteAddressMessage} />}
            </Button>
          </div>
        ))}
    </FlexBox>
  )
}
