import { Box, Button, Checkbox, Divider, FlexBox, Hidden, Icon, Spinner, Typography, Visible, useScreenClass } from '@vp/swan'
import classNames from 'classnames'
import clsx from 'clsx'
import { useDeletionInProgress } from 'lib/intl/msg-address-dialog.hooks'
import { useAddressError } from 'lib/intl/msg-address-errors.hook'
import { useMsgAlternateTextEditLogo } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgDeleteLabel } from 'lib/intl/msg-delete-action.hooks'
import { useMsgEditLabel } from 'lib/intl/msg-edit-action.hooks'
import { useOnDeleteHook } from 'modules/review/components/modal-delete/on-delete.hook'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { AddressType, CommonAddressRowProps } from 'modules/review/types/address-multi-selection.types'
import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AddressDisplayWithFallback } from '../address-display-with-fallback/address-display-with-fallback.component'
import { useAddressActions } from '../address-tab/address-actions.context'
import { useAddressSelection } from '../address-tab/address-selection.context'
import classes from './address-row.module.scss'
import { useAddressPreview } from 'modules/review/contexts/address-preview.context'
import { QUERY_KEY_MAILING_LIST_ID } from 'constants/query-key.constants'
import { getInitialValue } from 'hooks/use-query-string-state.hook'
import { PageName, useEventTracking } from 'lib/telemetry'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { AdditionalTrackingDataType, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'
import { withErrorBoundary } from 'lib/errors'

const AddressRowInternal: FC<CommonAddressRowProps<IAddressDetails>> = ({
  address,
  isSelected,
  rowId,
  showDivider,
  errors,
  type,
  isPreviewLoading,
  showExcess,
  suggestionAvailable,
  isLastRow,
}) => {
  const deleteLabel = useMsgDeleteLabel()
  const editLabel = useMsgEditLabel()
  const alternateTextEditLogo = useMsgAlternateTextEditLogo()
  const { onEdit } = useAddressActions()
  const { onToggle } = useAddressSelection()
  const errorMessages = useAddressError(errors)
  const intl = useIntl()
  const selectAddressMessage = intl.formatMessage({
    defaultMessage: 'Select address',
    description: 'Button label to select one of the duplicate addresses',
  })
  const deleteAddressMessage = useDeletionInProgress()
  const { onDelete, isLoading, isEnabled } = useOnDeleteHook([rowId], type)
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const isSavedListFlow = getInitialValue(QUERY_KEY_MAILING_LIST_ID, '')
  const previewRecipientMessage = intl
    .formatMessage({ defaultMessage: 'Preview recipient', description: 'Title for the recipient address to be previewed' })
    .concat(' ', address.firstName as string, ' ', address.lastName)
  const editRecipientMessage = intl
    .formatMessage({ defaultMessage: 'Edit recipient', description: 'Title for the recipient address to be edited' })
    .concat(' ', address.firstName as string, ' ', address.lastName)
  const deleteRecipientMessage = intl
    .formatMessage({ defaultMessage: 'Delete recipient', description: 'Title for the recipient address to be deleted' })
    .concat(' ', address.firstName as string, ' ', address.lastName)

  const { onPreview, actionConfig } = useAddressPreview()

  const previewBasedDisplay = clsx({ [classes.isPreviewLoaded]: !isPreviewLoading, [classes.isNotPreviewLoaded]: isPreviewLoading })

  const forSavedListFlow = !!isSavedListFlow && !loadingManualAddressingFlag && manualAddressingFeatureToggle
  const screenSize = useScreenClass()

  const fireTracking = useEventTracking()
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)

  const flyOutTrackingData: AdditionalTrackingDataType = {
    category: TrackingCategoryType.FLY_OUT,
    label: TrackingLabelType.FLY_OUT_SELECTION,
    pageName: trackingProductPageName,
  }

  const handleEdit = () => {
    fireTracking(TrackingEventType.FLY_OUT_VIEWED, flyOutTrackingData)
    onEdit(rowId)
  }
  return !forSavedListFlow ? (
    <Box className={classNames([actionConfig.rowIndex === rowId ? classes.activeClass : ''])}>
      <FlexBox
        marginTop={5}
        marginBottom={isLastRow ? 6 : 5}
        px={{ xs: 5, sm: 5, md: 6, lg: 6 }}
        className={classNames([showExcess ? classes.listRowExcess : classes.listRow, isLastRow ? classes.roundedCorners : ''])}
      >
        <label htmlFor={selectAddressMessage}></label>
        <Checkbox mt="2" mr={{ xs: 5, sm: 5, md: 7, lg: 7 }} id={selectAddressMessage} checked={isSelected || false} onChange={() => onToggle(rowId)} />
        <FlexBox className={classes.addressContent} flexDirection="column">
          <AddressDisplayWithFallback address={address} />
          {type === AddressType.INVALID && (
            <FlexBox alignItems="center" marginTop={3}>
              <Icon iconType="error" size="20p" skin="error" />
              <Typography textColor="error" fontSkin="body-small-bold" ml={2}>
                {suggestionAvailable && (
                  <FormattedMessage
                    defaultMessage="Invalid address. Suggested address available."
                    description="Message to inform availability of suggested address"
                  />
                )}
                {!suggestionAvailable && errorMessages.length === 0 && (
                  <FormattedMessage
                    defaultMessage="The deliverability of this address could not be confirmed, please check and correct the address."
                    description="Message to inform the user the deliverability of the address was not confirmed"
                  />
                )}
                {!suggestionAvailable && errorMessages.length === 1 && errorMessages[0]}
                {!suggestionAvailable && errorMessages.length > 1 && (
                  <FormattedMessage
                    defaultMessage="Multiple errors in recipient details"
                    description="error message to indicate multiple address field errors"
                  />
                )}
              </Typography>
            </FlexBox>
          )}
          {type === AddressType.VALID && showExcess && (
            <FlexBox alignItems="center" marginTop={3}>
              <Icon iconType="warning" size="20p" skin="warning" />
              <Typography fontSkin="body-small" ml={2}>
                <FormattedMessage defaultMessage="Not enough envelopes" description="Message to inform the user the discrepancy in envelopes quantity" />
              </Typography>
            </FlexBox>
          )}

          {!loadingManualAddressingFlag && !manualAddressingFeatureToggle && (
            <Hidden lg md>
              {type !== AddressType.INVALID && (
                <Hidden xs sm>
                  <Button
                    aria-label={previewRecipientMessage}
                    skin="unstyled"
                    className={previewBasedDisplay}
                    mr={'between-actions'}
                    onClick={() => (!isPreviewLoading ? onPreview({ address: { ...address }, rowIndex: rowId, addressType: type }) : '')}
                    disabled={rowId !== actionConfig.rowIndex && isPreviewLoading}
                  >
                    <FormattedMessage defaultMessage="Preview" description="Button label to preview an address" />
                  </Button>
                </Hidden>
              )}
              <Button aria-label={editRecipientMessage} skin="unstyled" style={{ textDecoration: 'underline' }} mr={4} onClick={() => onEdit(rowId)}>
                <FormattedMessage defaultMessage="Edit" description="Button label to edit an address" />
              </Button>
              <Button
                aria-label={deleteRecipientMessage}
                skin="unstyled"
                style={{ textDecoration: 'underline' }}
                onClick={onDelete}
                disabled={!isEnabled || isLoading}
              >
                <FormattedMessage defaultMessage="Delete" description="Button label to delete an address" />
                {isLoading && <Spinner accessibleText={deleteAddressMessage} />}
              </Button>
            </Hidden>
          )}
        </FlexBox>
        <Hidden sm xs md className={classes.roundButtons}>
          {type !== AddressType.INVALID && (
            <Button
              aria-label={previewRecipientMessage}
              buttonShape="round"
              className={previewBasedDisplay}
              style={{ minWidth: '40px' }} // Hack to fix the button square shape when setting an icon size
              mr={'between-actions'}
              onClick={() => (!isPreviewLoading ? onPreview({ address: { ...address }, rowIndex: rowId, addressType: type }) : '')}
              disabled={rowId !== actionConfig.rowIndex && isPreviewLoading}
            >
              <Icon iconType="preview" size="16p" skin="standard" alt={alternateTextEditLogo} />
            </Button>
          )}
          <Button
            aria-label={deleteRecipientMessage}
            buttonShape="round"
            onClick={onDelete}
            title={deleteLabel}
            disabled={!isEnabled || isLoading}
            style={{ minWidth: '40px' }}
            mr={'between-actions'}
          >
            <Icon iconType="delete" size="16p" skin="standard" className={classNames([isLoading ? classes.fadedLogo : ''])} />
            {isLoading && <Spinner className={classes.deletePreloader} accessibleText={deleteAddressMessage} />}
          </Button>
          <Button aria-label={editRecipientMessage} buttonShape="round" onClick={() => onEdit(rowId)} title={editLabel} style={{ minWidth: '40px' }}>
            <Icon iconType="edit" size="16p" skin="standard" alt={alternateTextEditLogo} />
          </Button>
        </Hidden>
      </FlexBox>

      {(screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md') && !loadingManualAddressingFlag && manualAddressingFeatureToggle && (
        <FlexBox className={classes.mobileViewUploadBtns} pb={5} px={{ xs: 5, sm: 5, md: 7, lg: 7 }} ml={{ xs: 5, sm: 5, md: 7, lg: 7 }}>
          <Visible md>
            {type !== AddressType.INVALID && (
              <Button
                aria-label={previewRecipientMessage}
                buttonShape="round"
                className={previewBasedDisplay}
                style={{ minWidth: '40px' }}
                mr={'between-actions'}
                onClick={() => (!isPreviewLoading ? onPreview({ address: { ...address }, rowIndex: rowId, addressType: type }) : '')}
                disabled={rowId !== actionConfig.rowIndex && isPreviewLoading}
              >
                <Icon iconType="preview" size="16p" skin="standard" alt={alternateTextEditLogo} />
              </Button>
            )}
          </Visible>
          <Button
            aria-label={deleteRecipientMessage}
            buttonShape="round"
            onClick={onDelete}
            title={deleteLabel}
            disabled={!isEnabled || isLoading}
            style={{ minWidth: '40px' }}
            mr={'between-actions'}
          >
            <Icon iconType="delete" size="16p" skin="standard" className={classNames([isLoading ? classes.fadedLogo : ''])} />
            {isLoading && <Spinner className={classes.deletePreloader} accessibleText={deleteAddressMessage} />}
          </Button>
          <Button
            aria-label={editRecipientMessage}
            buttonShape="round"
            mr={'between-actions'}
            onClick={() => onEdit(rowId)}
            title={editLabel}
            style={{ minWidth: '40px' }}
          >
            <Icon iconType="edit" size="16p" skin="standard" alt={alternateTextEditLogo} />
          </Button>
        </FlexBox>
      )}

      {showDivider ? (
        <FlexBox className={classes.rowDivider} style={{ background: `${showExcess ? 'subtle' : 'standard'}` }}>
          <Divider mx={{ xs: 5, sm: 5, md: 6, lg: 6 }} my={0} />
        </FlexBox>
      ) : null}
    </Box>
  ) : (
    <Box className={classNames([actionConfig.rowIndex === rowId ? classes.activeClass : ''])}>
      <FlexBox
        marginTop={5}
        marginBottom={isLastRow ? 6 : 5}
        px={{ xs: 5, sm: 5, md: 6, lg: 6 }}
        className={classNames([showExcess ? classes.listRowExcess : classes.listRow, isLastRow ? classes.roundedCorners : ''])}
        flexDirection={screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md' ? 'column' : 'row'}
      >
        {(screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md') && (
          <>
            <FlexBox>
              <label htmlFor={selectAddressMessage}></label>
              <Checkbox mt="2" mr={{ xs: 5, sm: 5, md: 7, lg: 7 }} id={selectAddressMessage} checked={isSelected || false} onChange={() => onToggle(rowId)} />
              <FlexBox className={classes.addressContent} flexDirection="column">
                <AddressDisplayWithFallback address={address} />
                {type === AddressType.INVALID && (
                  <FlexBox alignItems="center" marginTop={3}>
                    <Icon iconType="error" size="20p" skin="error" />
                    <Typography textColor="error" fontSkin="body-small-bold" ml={2}>
                      {suggestionAvailable && (
                        <FormattedMessage
                          defaultMessage="Invalid address. Suggested address available."
                          description="Message to inform availability of suggested address"
                        />
                      )}
                      {!suggestionAvailable && errorMessages.length === 0 && (
                        <FormattedMessage
                          defaultMessage="The deliverability of this address could not be confirmed, please check and correct the address."
                          description="Message to inform the user the deliverability of the address was not confirmed"
                        />
                      )}
                      {!suggestionAvailable && errorMessages.length === 1 && errorMessages[0]}
                      {!suggestionAvailable && errorMessages.length > 1 && (
                        <FormattedMessage
                          defaultMessage="Multiple errors in recipient details"
                          description="error message to indicate multiple address field errors"
                        />
                      )}
                    </Typography>
                  </FlexBox>
                )}
                {type === AddressType.VALID && showExcess && (
                  <FlexBox alignItems="center" marginTop={3}>
                    <Icon iconType="warning" size="20p" skin="warning" />
                    <Typography fontSkin="body-small" ml={2}>
                      <FormattedMessage defaultMessage="Not enough envelopes" description="Message to inform the user the discrepancy in envelopes quantity" />
                    </Typography>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox className={classes.isMobileView} ml={{ xs: 5, sm: 5, md: 7, lg: 7 }} pt={4} marginTop={3}>
              <Visible md>
                {type !== AddressType.INVALID && (
                  <Button
                    aria-label={previewRecipientMessage}
                    buttonShape="round"
                    className={previewBasedDisplay}
                    style={{ minWidth: '40px' }}
                    mr={'between-actions'}
                    onClick={() => (!isPreviewLoading ? onPreview({ address: { ...address }, rowIndex: rowId, addressType: type }) : '')}
                    disabled={rowId !== actionConfig.rowIndex && isPreviewLoading}
                  >
                    <Icon iconType="preview" size="16p" skin="standard" alt={alternateTextEditLogo} />
                  </Button>
                )}
              </Visible>
              <Button
                aria-label={deleteRecipientMessage}
                buttonShape="round"
                onClick={onDelete}
                title={deleteLabel}
                disabled={!isEnabled || isLoading}
                style={{ minWidth: '40px' }}
                mr={'between-actions'}
              >
                <Icon iconType="delete" size="16p" skin="standard" className={classNames([isLoading ? classes.fadedLogo : ''])} />
                {isLoading && <Spinner className={classes.deletePreloader} accessibleText={deleteAddressMessage} />}
              </Button>
              <Button
                aria-label={editRecipientMessage}
                buttonShape="round"
                mr={'between-actions'}
                style={{ minWidth: '40px' }}
                onClick={() => onEdit(rowId)}
                title={editLabel}
              >
                <Icon iconType="edit" size="16p" skin="standard" alt={alternateTextEditLogo} />
              </Button>
            </FlexBox>
          </>
        )}

        {(screenSize === 'lg' || screenSize === 'xl') && (
          <>
            <label htmlFor={selectAddressMessage}></label>
            <Checkbox mt="2" mr={{ xs: 5, sm: 5, md: 7, lg: 7 }} id={selectAddressMessage} checked={isSelected || false} onChange={() => onToggle(rowId)} />
            <FlexBox className={classes.addressContent} flexDirection="column">
              <AddressDisplayWithFallback address={address} />
              {type === AddressType.INVALID && (
                <FlexBox alignItems="center" marginTop={3}>
                  <Icon iconType="error" size="20p" skin="error" />
                  <Typography textColor="error" fontSkin="body-small-bold" ml={2}>
                    {suggestionAvailable && (
                      <FormattedMessage
                        defaultMessage="Invalid address. Suggested address available."
                        description="Message to inform availability of suggested address"
                      />
                    )}
                    {!suggestionAvailable && errorMessages.length === 0 && (
                      <FormattedMessage
                        defaultMessage="The deliverability of this address could not be confirmed, please check and correct the address."
                        description="Message to inform the user the deliverability of the address was not confirmed"
                      />
                    )}
                    {!suggestionAvailable && errorMessages.length === 1 && errorMessages[0]}
                    {!suggestionAvailable && errorMessages.length > 1 && (
                      <FormattedMessage
                        defaultMessage="Multiple errors in recipient details"
                        description="error message to indicate multiple address field errors"
                      />
                    )}
                  </Typography>
                </FlexBox>
              )}
              {type === AddressType.VALID && showExcess && (
                <FlexBox alignItems="center" marginTop={3}>
                  <Icon iconType="warning" size="20p" skin="warning" />
                  <Typography fontSkin="body-small" ml={2}>
                    <FormattedMessage defaultMessage="Not enough envelopes" description="Message to inform the user the discrepancy in envelopes quantity" />
                  </Typography>
                </FlexBox>
              )}
            </FlexBox>

            <Hidden className={classes.roundButtons}>
              {type !== AddressType.INVALID && (
                <Button
                  aria-label={previewRecipientMessage}
                  buttonShape="round"
                  className={previewBasedDisplay}
                  style={{ minWidth: '40px' }}
                  mr={'between-actions'}
                  onClick={() => (!isPreviewLoading ? onPreview({ address: { ...address }, rowIndex: rowId, addressType: type }) : '')}
                  disabled={rowId !== actionConfig.rowIndex && isPreviewLoading}
                >
                  <Icon iconType="preview" size="16p" skin="standard" alt={alternateTextEditLogo} />
                </Button>
              )}

              <Button
                aria-label={deleteRecipientMessage}
                buttonShape="round"
                onClick={onDelete}
                title={deleteLabel}
                disabled={!isEnabled || isLoading}
                style={{ minWidth: '40px' }}
                mr={'between-actions'}
              >
                <Icon iconType="delete" size="16p" skin="standard" className={classNames([isLoading ? classes.fadedLogo : ''])} />
                {isLoading && <Spinner className={classes.deletePreloader} accessibleText={deleteAddressMessage} />}
              </Button>
              <Button
                aria-label={editRecipientMessage}
                buttonShape="round"
                mr={'between-actions'}
                style={{ minWidth: '40px' }}
                onClick={handleEdit}
                title={editLabel}
              >
                <Icon iconType="edit" size="16p" skin="standard" alt={alternateTextEditLogo} />
              </Button>
            </Hidden>
          </>
        )}
      </FlexBox>

      {showDivider ? (
        <FlexBox className={classes.rowDivider} style={{ background: `${showExcess ? 'selected-active' : 'standard'}` }}>
          <Divider mx={{ xs: 5, sm: 5, md: 6, lg: 6 }} my={0} />
        </FlexBox>
      ) : null}
    </Box>
  )
}

export const AddressRow = withErrorBoundary(AddressRowInternal)
