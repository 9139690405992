import { SelectionStrategy } from 'modules/review/types/address-list.types'
import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import { Icon } from '@vp/swan'

export function useStrategySelectorOptions() {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  return [
    {
      value: SelectionStrategy.AUTO,
      text: intl.formatMessage({
        defaultMessage: 'Fix the issues for me',
        description: 'Option for automatically fixing the issues',
      }),
      subText: isPostcard
        ? intl.formatMessage({
            defaultMessage:
              'VistaPrint will automatically merge duplicates, keep same household duplicates, remove errors and send one postcard to each valid address.',
          })
        : intl.formatMessage({
            defaultMessage: 'VistaPrint will automatically merge duplicates and remove errors.',
          }),
      badgeText: intl.formatMessage({ defaultMessage: 'Recommended', description: 'Label for the recommended option' }),
      icon: <Icon iconType="sparkle" size="20p" skin="standard" />,
      altText: intl.formatMessage({
        defaultMessage: 'Auto Resolve icon',
        description: 'Alternative text for automatically resolving icon',
      }),
    },
    {
      value: SelectionStrategy.MANUAL,
      text: intl.formatMessage({
        defaultMessage: "I'll fix the issues myself",
        description: 'Option for manually fixing the issues',
      }),
      subText: isPostcard
        ? intl.formatMessage({
            defaultMessage: `I'll review and edit each duplicate and error myself, then VistaPrint will send one postcard to each valid address.`,
          })
        : intl.formatMessage({
            defaultMessage: `I'll review and edit each duplicate and error myself.`,
          }),
      badgeText: null,
      icon: <Icon iconType="edit" size="20p" skin="standard" />,
      altText: intl.formatMessage({
        defaultMessage: 'Manual resolve icon',
        description: 'Alternative text for manually resolving icon',
      }),
    },
  ]
}
