import { ErrorBoundary } from 'lib/errors'
import { useProductInfoQtySelection } from 'lib/product-info'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
import { getIdForAddressDetail } from 'modules/review/utilities/address.utils'
import { FC, useEffect, useMemo } from 'react'
import { AddressRow } from '../address-row/address-row.component'
import { AddressActionsProvider } from './address-actions.context'
import { AddressSelectionProvider, useAddressSelection } from './address-selection.context'

const ValidAddressTabContent: FC<{
  addresses: IAddressDetails[]
  isPreviewLoading: boolean
}> = ({ addresses, isPreviewLoading }) => {
  const { currentPage, pageSize, selection, onReset } = useAddressSelection()

  const displayAddresses = useMemo(
    () => addresses?.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize),
    [addresses, currentPage, pageSize],
  )
  const { selectedQty } = useProductInfoQtySelection()

  useEffect(() => {
    onReset(addresses.map(getIdForAddressDetail))
  }, [onReset, addresses])

  return (
    <>
      {displayAddresses.map((address, index, allAddresses) => {
        const key = getIdForAddressDetail(address)
        return (
          <AddressRow
            type={AddressType.VALID}
            key={key}
            rowId={key}
            isSelected={selection[key]}
            address={address}
            showDivider={index < allAddresses.length - 1}
            showExcess={(currentPage - 1) * pageSize + index >= selectedQty}
            isPreviewLoading={isPreviewLoading}
          />
        )
      })}
    </>
  )
}

export const ValidAddressTab: FC<{
  addresses: IAddressDetails[]
  isPreviewLoading: boolean
}> = ({ addresses, isPreviewLoading }) => (
  <ErrorBoundary>
    <AddressActionsProvider addressType={AddressType.VALID}>
      <AddressSelectionProvider addressType={AddressType.VALID} totalAddressCount={addresses.length}>
        <ValidAddressTabContent addresses={addresses} isPreviewLoading={isPreviewLoading} />
      </AddressSelectionProvider>
    </AddressActionsProvider>
  </ErrorBoundary>
)
