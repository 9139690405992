import {
  Button,
  FlexBox,
  Icon,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  Typography,
} from '@vp/swan'
import { useMsgAddressFormatAriaLabel, useMsgConfirmDeliverability, useMsgStandardize, useMsgZip } from 'lib/intl/msg-address-format-info.hooks'
import { useMsgAlternateTextValid } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

export const AddressFormatInfo: FC<unknown> = () => {
  const alternateTextValid = useMsgAlternateTextValid()
  const [isOpen, setIsOpen] = useState(false)
  const modalCloseLabel = useMsgModalClose()
  const confirmDeliverability = useMsgConfirmDeliverability()
  const standardize = useMsgStandardize()
  const zip = useMsgZip()
  const addressFormatAriaLabel = useMsgAddressFormatAriaLabel()
  const addressFormatInfo = [
    {
      label: confirmDeliverability,
    },
    {
      label: standardize,
    },
    {
      label: zip,
    },
  ]

  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const intl = useIntl()
  const message = manualAddressingFeatureToggle
    ? intl.formatMessage({
        defaultMessage:
          'To ensure everything is delivered safely, we standardized your address formats. Any changes you make to your recipient list will be saved. ',
      })
    : intl.formatMessage({
        defaultMessage:
          'To ensure everything is delivered safely, we standardized your address formats. Any changes you make to your address list will be saved. ',
      })

  const buttonMessage = intl.formatMessage({ defaultMessage: 'See address format information' })
  return (
    <>
      <Typography fontSkin="body-small" textColor="subtle" mb={6}>
        {!loadingManualAddressingFlag && (
          <>
            {message}{' '}
            <Button skin="unstyled" style={{ textDecoration: 'underline', display: 'inline' }} onClick={() => setIsOpen(true)}>
              {buttonMessage}
            </Button>
          </>
        )}
      </Typography>
      <ModalDialog isOpen={isOpen} onRequestDismiss={() => setIsOpen(false)}>
        <ModalDialogContent aria-labelledby={addressFormatAriaLabel}>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
          </ModalDialogNav>
          <ModalDialogHeader>
            <ModalDialogTitle>
              {!loadingManualAddressingFlag &&
                (manualAddressingFeatureToggle ? (
                  <FormattedMessage defaultMessage="Whenever you upload an recipient list, we review each recipient and:" />
                ) : (
                  <FormattedMessage defaultMessage="Whenever you upload an address list, we review each recipient and:" />
                ))}
            </ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            {addressFormatInfo.map(unit => (
              <FlexBox mb={4} alignItems="center" key={unit.label}>
                <Icon iconType="success" size="16p" skin="success" alt={alternateTextValid} />
                <Typography fontSize={'small'} ml={4} fontWeight="bold" as="span">
                  {unit.label}
                </Typography>
              </FlexBox>
            ))}
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
