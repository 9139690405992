export class Notifier {
  callbacks: { (): void }[]

  constructor() {
    this.callbacks = []
  }

  subscribe(callback: () => void) {
    this.callbacks.push(callback)
  }

  unsubscribe(callback: () => void) {
    const index = this.callbacks.indexOf(callback)
    if (index !== -1) {
      this.callbacks.splice(index, 1)
    }
  }

  notify() {
    for (const callback of this.callbacks) {
      callback()
    }
  }
}
