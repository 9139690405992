import axios, { AxiosInstance } from 'axios'
import { AME_REQUESTER, MSP_SITE_FLOW_BASE, MSP_TENANT } from 'constants/env.constants'
import { SiteFlowNextStep } from 'types/siteflow.types'

export const siteFlowApiClient: AxiosInstance = axios.create({
  baseURL: MSP_SITE_FLOW_BASE,
})

export async function getSiteFlowNextStep({
  locale,
  mpvId,
  workId,
  predictFlow = false,
  signal,
  currentExperienceType,
}: {
  locale: string
  mpvId: string
  workId: string
  currentExperienceType: string
  predictFlow: boolean
  signal?: AbortSignal
}): Promise<SiteFlowNextStep> {
  const response = await siteFlowApiClient.get<SiteFlowNextStep>(
    `v2/nextstep/${MSP_TENANT}/${locale}/${currentExperienceType}/${mpvId}?predictFlow=${predictFlow.toString()}&requestor=${AME_REQUESTER}`,
    {
      signal,
    },
  )
  response.data.url = response.data.url.replace('{workId}', workId)
  response.data.predictedFlow = response.data.predictedFlow.map(u => ({ ...u, url: u.url.replace('{workId}', workId) }))
  return response.data
}
