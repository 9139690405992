import { IAddressDetails } from 'modules/review/types/address-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { noOp } from 'utilities/functions.utils'
import { PreviewActionConfig } from '../types/address-preview.types'

export type ActionFn = ({ rowIndex, address, addressType }: PreviewActionConfig) => void
export type AddressPreviewContextValue = {
  actionConfig: PreviewActionConfig
  onPreview: ActionFn
}
const AddressPreviewContext = createContext<AddressPreviewContextValue>({
  actionConfig: { rowIndex: '', address: {} as IAddressDetails, addressType: '' as AddressType },
  onPreview: noOp,
})

export const AddressPreviewProvider: FC<PropsWithChildren> = ({ children }) => {
  const [actionConfig, setActionConfig] = useState<PreviewActionConfig>({
    rowIndex: '',
    address: {} as IAddressDetails,
    addressType: '' as AddressType,
  })
  const onPreview = useCallback(({ rowIndex, address, addressType }: PreviewActionConfig) => {
    setActionConfig({ rowIndex, address, addressType })
  }, [])

  const actionValue = useMemo(
    (): AddressPreviewContextValue => ({
      actionConfig,
      onPreview,
    }),
    [actionConfig, onPreview],
  )

  return <AddressPreviewContext.Provider value={actionValue}>{children}</AddressPreviewContext.Provider>
}

export function useAddressPreview() {
  return useContext(AddressPreviewContext)
}
