import { useIntl } from 'react-intl'

export function useMsgAddressDialogHeading() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'My address', description: 'Heading for the address of the user' })
}

export function useMsgAddressEditInformation() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Edit address information',
    description: 'Label for editing the address information',
  })
}

export function useMsgAddressAddInformation() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Add address information',
    description: 'Label for Adding the address information',
  })
}

export function useMsgAddressCurrent() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Current', description: 'Used as First Name for Current Resident' })
}

export function useMsgAddressResidents() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Residents', description: 'Used as Last Name for Current Resident' })
}

export function useSpinnerSubmissionConfirm() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Address from submission in progress',
    description: 'Label address from submission',
  })
}

export function useDeletionInProgress() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Address deletion in progress',
    description: 'Label for deleting the address',
  })
}

export function useSuggestedAddressTitle() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Suggested address',
    description: 'Title for suggested address',
  })
}
